import { useCallback } from 'react';
import { NButton } from 'nayan';
import { RazorpayOrderOptions, useRazorpay } from 'react-razorpay';
import { CONFIG } from '@/shared/config/config';
import { QUERY_KEYS } from '@/shared/hooks/hooksUtils';
import { RestService } from '@/shared/services/RestService';
import { useUserStore } from '@/shared/stores/UserStore';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  title: string;
  amount: number;
  videoUuid?: string;
  disabled?: boolean;
}

const Payments = (props: Props) => {
  const queryClient = useQueryClient();
  const { title, amount, videoUuid = '', disabled = false } = props;
  const userUuid = useUserStore.getState().UUID;
  const { error, isLoading, Razorpay } = useRazorpay();

  const handlePayment = useCallback(async () => {
    const payment: any = await RestService.post('/payments', { amount, videoUuid, userUuid });

    const options: RazorpayOrderOptions = {
      key: CONFIG.PAYMENT_KEY,
      amount,
      currency: 'INR',
      name: CONFIG.DISPLAY_NAME,
      description: CONFIG.TITLE,
      image: CONFIG.APP_URL + '/logo.webp',
      order_id: payment?.data?.order?.id,
      handler: async res => {
        const updatedPayment = await RestService.update('/payments/' + payment?.data?.uuid, { uuid: payment?.data?.uuid, userUuid, payment: res });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENTS] });
        updatedPayment.data?.videoUuid && (await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VIDEOS, updatedPayment.data?.videoUuid] }));
      },
      prefill: {
        name: useUserStore.getState().UNAME || 'Inyter User',
        email: useUserStore.getState().UEMAIL || 'hello@inyter.com',
        contact: useUserStore.getState().UMOBILE || '9620209040'
      },
      notes: useUserStore.getState().UUID,
      theme: {
        color: '#005ee6'
      }
    };

    const rzPay = new Razorpay(options);
    rzPay.open();
  }, [Razorpay]);

  if (error) {
    console.log(error);
    return null;
  }

  return (
    <NButton isLoading={isLoading} onClick={handlePayment} disabled={disabled}>
      {isLoading ? 'Loading...' : title}
    </NButton>
  );
};

export default Payments;
