import { HowItWorks } from '@/ui/components/helpers/HowItWorks';

const Process = () => {
  return (
    <section className="bg-background flex flex-col justify-center text">
      <div className="container mx-auto px-3 sm:px-0 mt-12">
        <HowItWorks />
      </div>
    </section>
  );
};

export default Process;
