import { create } from 'zustand';
import { immerMiddleware } from '../services/Utils';

const appStore = () => ({
  appSocket: null,
  appTheme: '',
  isLoaded: false,
  hideWrapper: false,
  templates: null
});

export const useAppStore = create(immerMiddleware(appStore));
