import ProductAdvertisement2 from '@/remotion/compositions/advertisements/ProductAdvertisement2.tsx';
import ProductAdvertisement3 from '@/remotion/compositions/advertisements/ProductAdvertisement3.tsx';
import ProductAdvertisement4 from '@/remotion/compositions/advertisements/ProductAdvertisement4.tsx';
import ProductAdvertisement from '@/remotion/compositions/advertisements/ProductAdvertisement.tsx';
import BirthdayInvitationWithAbstractDoubleRotation from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractDoubleRotation.tsx';
import BirthdayInvitationWithAbstractFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractFlowers.tsx';
import BirthdayInvitationWithAbstractLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractLeaves.tsx';
import BirthdayInvitationWithBibleReading from '@/remotion/compositions/birthday/BirthdayInvitationWithBibleReading.tsx';
import BirthdayInvitationWithBlueAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithBlueAbstract.tsx';
import BirthdayInvitationWithBlueLights from '@/remotion/compositions/birthday/BirthdayInvitationWithBlueLights.tsx';
import BirthdayInvitationWithChristmasCross from '@/remotion/compositions/birthday/BirthdayInvitationWithChristmasCross.tsx';
import BirthdayInvitationWithChristmasTree from '@/remotion/compositions/birthday/BirthdayInvitationWithChristmasTree.tsx';
import BirthdayInvitationWithColorfulBlasts from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulBlasts.tsx';
import BirthdayInvitationWithColorfulFireworks from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulFireworks.tsx';
import BirthdayInvitationWithColorfulLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulLeaves.tsx';
import BirthdayInvitationWithDarkGreenAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithDarkGreenAbstract.tsx';
import BirthdayInvitationWithEagleAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithEagleAbstract.tsx';
import BirthdayInvitationWithFire from '@/remotion/compositions/birthday/BirthdayInvitationWithFire.tsx';
import BirthdayInvitationWithFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowers.tsx';
import BirthdayInvitationWithFlowersAndButterflys from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersAndButterflys.tsx';
import BirthdayInvitationWithFlowersAndLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersAndLeaves.tsx';
import BirthdayInvitationWithFlowersWithGreenBackground from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersWithGreenBackground.tsx';
import BirthdayInvitationWithFlowerVines from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowerVines.tsx';
import BirthdayInvitationWithGarlands from '@/remotion/compositions/birthday/BirthdayInvitationWithGarlands.tsx';
import BirthdayInvitationWithGlowingHearts from '@/remotion/compositions/birthday/BirthdayInvitationWithGlowingHearts.tsx';
import BirthdayInvitationWithGoldenLights from '@/remotion/compositions/birthday/BirthdayInvitationWithGoldenLights.tsx';
import BirthdayInvitationWithGreenAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithGreenAbstract.tsx';
import BirthdayInvitationWithHomeFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithHomeFlowers.tsx';
import BirthdayInvitationWithLeavesAndGreenBG from '@/remotion/compositions/birthday/BirthdayInvitationWithLeavesAndGreenBG.tsx';
import BirthdayInvitationWithLights from '@/remotion/compositions/birthday/BirthdayInvitationWithLights.tsx';
import BirthdayInvitationWithMarronAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithMarronAbstract.tsx';
import BirthdayInvitationWithMoonAndLights from '@/remotion/compositions/birthday/BirthdayInvitationWithMoonAndLights.tsx';
import BirthdayInvitationWithPurpleAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithPurpleAbstract.tsx';
import BirthdayInvitationWithRaining from '@/remotion/compositions/birthday/BirthdayInvitationWithRaining.tsx';
import BirthdayInvitationWithRotatingFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithRotatingFlowers.tsx';
import BirthdayInvitationWithRotatingRangoli from '@/remotion/compositions/birthday/BirthdayInvitationWithRotatingRangoli.tsx';
import BirthdayInvitationWithScrollingDiyas from '@/remotion/compositions/birthday/BirthdayInvitationWithScrollingDiyas.tsx';
import BirthdayInvitationWithSnowing from '@/remotion/compositions/birthday/BirthdayInvitationWithSnowing.tsx';
import BirthdayInvitationWithVintage from '@/remotion/compositions/birthday/BirthdayInvitationWithVintage.tsx';
import BirthdayInvitationWithVintageGreen from '@/remotion/compositions/birthday/BirthdayInvitationWithVintageGreen.tsx';
import BirthdayInvitationWithVintageOrange from '@/remotion/compositions/birthday/BirthdayInvitationWithVintageOrange.tsx';
import BirthdayInvitationWithYellowAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithYellowAbstract.tsx';
import BirthdayInvitationWithYellowStarAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithYellowStarAbstract.tsx';
import BirthdayWishesWithBalloons from '@/remotion/compositions/birthday/BirthdayWishesWithBalloons.tsx';
import BirthdayWishesWithFlowers from '@/remotion/compositions/birthday/BirthdayWishesWithFlowers';
import BirthdayWishesWithSprings from '@/remotion/compositions/birthday/BirthdayWishesWithSprings.tsx';
import BirthdayWishesWithVectors from '@/remotion/compositions/birthday/BirthdayWishesWithVectors.tsx';
import FestivalWishesForChristmas from '@/remotion/compositions/festivals/FestivalWishesForChristmas';
import FestivalWishesForChristmasWithBalls from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithBalls.tsx';
import FestivalWishesForChristmasWithGiftBox from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithGiftBox.tsx';
import FestivalWishesForChristmasWithGreenSnow from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithGreenSnow.tsx';
import FestivalWishesForChristmasWithLights from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithLights.tsx';
import FestivalWishesForChristmasWithLightsAndSnow from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithLightsAndSnow.tsx';
import FestivalWishesForChristmasWithMoon from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithMoon.tsx';
import FestivalWishesForChristmasWithMountains from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithMountains.tsx';
import FestivalWishesForChristmasWithSantaClaus from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithSantaClaus.tsx';
import FestivalWishesForChristmasWithSnow from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithSnow.tsx';
import FestivalWishesForChristmasWithSnowingHouses from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithSnowingHouses.tsx';
import FestivalWishesForChristmasWithSnowman from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithSnowman.tsx';
import FestivalWishesForChristmasWithTree from '@/remotion/compositions/festivals/FestivalWishesForChristmasWithTree.tsx';
import FestivalWishesForDiwali from '@/remotion/compositions/festivals/FestivalWishesForDiwali';
import FestivalWishesForDiwaliWithDiyas from '@/remotion/compositions/festivals/FestivalWishesForDiwaliWithDiyas';
import FestivalWishesForHoli from '@/remotion/compositions/festivals/FestivalWishesForHoli';
import FestivalWishesForIndependenceDay from '@/remotion/compositions/festivals/FestivalWishesForIndependenceDay';
import FestivalWishesForRakshaBandan from '@/remotion/compositions/festivals/FestivalWishesForRakshaBandan';
import FestivalWishesForRamadan from '@/remotion/compositions/festivals/FestivalWishesForRamadan';
import FestivalWishesForSnowman from '@/remotion/compositions/festivals/FestivalWishesForSnowman';
import HouseWarmingInvitationWithAbstractDoubleRotation from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractDoubleRotation.tsx';
import HouseWarmingInvitationWithAbstractFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractFlowers.tsx';
import HouseWarmingInvitationWithAbstractLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractLeaves.tsx';
import HouseWarmingInvitationWithBibleReading from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBibleReading.tsx';
import HouseWarmingInvitationWithBlueAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBlueAbstract.tsx';
import HouseWarmingInvitationWithBlueLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBlueLights.tsx';
import HouseWarmingInvitationWithChristmasCross from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithChristmasCross.tsx';
import HouseWarmingInvitationWithChristmasTree from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithChristmasTree.tsx';
import HouseWarmingInvitationWithColorfulBlasts from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulBlasts.tsx';
import HouseWarmingInvitationWithColorfulFireworks from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulFireworks.tsx';
import HouseWarmingInvitationWithColorfulLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulLeaves.tsx';
import HouseWarmingInvitationWithDarkGreenAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithDarkGreenAbstract.tsx';
import HouseWarmingInvitationWithEagleAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithEagleAbstract.tsx';
import HouseWarmingInvitationWithFire from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFire.tsx';
import HouseWarmingInvitationWithFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowers.tsx';
import HouseWarmingInvitationWithFlowersAndButterflys from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersAndButterflys.tsx';
import HouseWarmingInvitationWithFlowersAndLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersAndLeaves.tsx';
import HouseWarmingInvitationWithFlowersWithGreenBackground from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersWithGreenBackground.tsx';
import HouseWarmingInvitationWithFlowerVines from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowerVines.tsx';
import HouseWarmingInvitationWithGarlands from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGarlands.tsx';
import HouseWarmingInvitationWithGlowingHearts from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGlowingHearts.tsx';
import HouseWarmingInvitationWithGoldenLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGoldenLights.tsx';
import HouseWarmingInvitationWithGreenAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGreenAbstract.tsx';
import HouseWarmingInvitationWithHomeFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithHomeFlowers.tsx';
import HouseWarmingInvitationWithLeavesAndGreenBG from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithLeavesAndGreenBG.tsx';
import HouseWarmingInvitationWithLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithLights.tsx';
import HouseWarmingInvitationWithMarronAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithMarronAbstract.tsx';
import HouseWarmingInvitationWithMoonAndLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithMoonAndLights.tsx';
import HouseWarmingInvitationWithPurpleAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithPurpleAbstract.tsx';
import HouseWarmingInvitationWithRaining from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRaining.tsx';
import HouseWarmingInvitationWithRotatingFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRotatingFlowers.tsx';
import HouseWarmingInvitationWithRotatingRangoli from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRotatingRangoli.tsx';
import HouseWarmingInvitationWithScrollingDiyas from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithScrollingDiyas.tsx';
import HouseWarmingInvitationWithSnowing from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithSnowing.tsx';
import HouseWarmingInvitationWithVintage from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintage.tsx';
import HouseWarmingInvitationWithVintageGreen from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintageGreen.tsx';
import HouseWarmingInvitationWithVintageOrange from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintageOrange.tsx';
import HouseWarmingInvitationWithYellowAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithYellowAbstract.tsx';
import HouseWarmingInvitationWithYellowStarAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithYellowStarAbstract.tsx';
import AnniversaryWishesWithBalloons from '@/remotion/compositions/life-events/AnniversaryWishesWithBalloons.tsx';
import AnniversaryWishesWithFlowers from '@/remotion/compositions/life-events/AnniversaryWishesWithFlowers.tsx';
import AnniversaryWishesWithSprings from '@/remotion/compositions/life-events/AnniversaryWishesWithSprings.tsx';
import AnniversaryWishesWithVectors from '@/remotion/compositions/life-events/AnniversaryWishesWithVectors.tsx';
import EngagementInvitationFlowersWithGreenBackground from '@/remotion/compositions/marriage/EngagementInvitationFlowersWithGreenBackground.tsx';
import EngagementInvitationWithAbstractDoubleRotation from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractDoubleRotation.tsx';
import EngagementInvitationWithAbstractFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractFlowers.tsx';
import EngagementInvitationWithAbstractLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractLeaves.tsx';
import EngagementInvitationWithBibleReading from '@/remotion/compositions/marriage/EngagementInvitationWithBibleReading.tsx';
import EngagementInvitationWithBlueAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithBlueAbstract.tsx';
import EngagementInvitationWithBlueLights from '@/remotion/compositions/marriage/EngagementInvitationWithBlueLights.tsx';
import EngagementInvitationWithChristmasCross from '@/remotion/compositions/marriage/EngagementInvitationWithChristmasCross.tsx';
import EngagementInvitationWithChristmasTree from '@/remotion/compositions/marriage/EngagementInvitationWithChristmasTree.tsx';
import EngagementInvitationWithColorfulBlasts from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulBlasts.tsx';
import EngagementInvitationWithColorfulFireworks from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulFireworks.tsx';
import EngagementInvitationWithColorfulLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulLeaves.tsx';
import EngagementInvitationWithDarkGreenAbstarct from '@/remotion/compositions/marriage/EngagementInvitationWithDarkGreenAbstarct.tsx';
import EngagementInvitationWithEagleAbstarct from '@/remotion/compositions/marriage/EngagementInvitationWithEagleAbstarct.tsx';
import EngagementInvitationWithFire from '@/remotion/compositions/marriage/EngagementInvitationWithFire.tsx';
import EngagementInvitationWithFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithFlowers.tsx';
import EngagementInvitationWithFlowersAndButterflys from '@/remotion/compositions/marriage/EngagementInvitationWithFlowersAndButterflys.tsx';
import EngagementInvitationWithFlowersAndLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithFlowersAndLeaves.tsx';
import EngagementInvitationWithFlowerVines from '@/remotion/compositions/marriage/EngagementInvitationWithFlowerVines.tsx';
import EngagementInvitationWithGlowingHearts from '@/remotion/compositions/marriage/EngagementInvitationWithGlowingHearts.tsx';
import EngagementInvitationWithGoldenLights from '@/remotion/compositions/marriage/EngagementInvitationWithGoldenLights.tsx';
import EngagementInvitationWithGreenAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithGreenAbstract.tsx';
import EngagementInvitationWithHomeFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithHomeFlowers.tsx';
import EngagementInvitationWithLeavesAndGreenBG from '@/remotion/compositions/marriage/EngagementInvitationWithLeavesAndGreenBG.tsx';
import EngagementInvitationWithLights from '@/remotion/compositions/marriage/EngagementInvitationWithLights.tsx';
import EngagementInvitationWithMarronAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithMarronAbstract.tsx';
import EngagementInvitationWithMoonAndLights from '@/remotion/compositions/marriage/EngagementInvitationWithMoonAndLights.tsx';
import EngagementInvitationWithPurpleAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithPurpleAbstract.tsx';
import EngagementInvitationWithRaining from '@/remotion/compositions/marriage/EngagementInvitationWithRaining.tsx';
import EngagementInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithRotatingFlowers.tsx';
import EngagementInvitationWithRotatingRangoli from '@/remotion/compositions/marriage/EngagementInvitationWithRotatingRangoli.tsx';
import EngagementInvitationWithScrollingDiyas from '@/remotion/compositions/marriage/EngagementInvitationWithScrollingDiyas.tsx';
import EngagementInvitationWithSnowing from '@/remotion/compositions/marriage/EngagementInvitationWithSnowing.tsx';
import EngagementInvitationWithVintage from '@/remotion/compositions/marriage/EngagementInvitationWithVintage.tsx';
import EngagementInvitationWithVintageGreen from '@/remotion/compositions/marriage/EngagementInvitationWithVintageGreen.tsx';
import EngagementInvitationWithVintageOrange from '@/remotion/compositions/marriage/EngagementInvitationWithVintageOrange.tsx';
import EngagementInvitationWithYellowAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithYellowAbstract.tsx';
import EngagementInvitationWithYellowStarAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithYellowStarAbstract.tsx';
import MarriageInvitationFlowersWithGreenBackground from '@/remotion/compositions/marriage/MarriageInvitationFlowersWithGreenBackground.tsx';
import MarriageInvitationWithAbstractDoubleRotation from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractDoubleRotation.tsx';
import MarriageInvitationWithAbstractFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractFlowers.tsx';
import MarriageInvitationWithAbstractLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractLeaves.tsx';
import MarriageInvitationWithBibleReading from '@/remotion/compositions/marriage/MarriageInvitationWithBibleReading.tsx';
import MarriageInvitationWithBlueAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithBlueAbstract.tsx';
import MarriageInvitationWithBlueLights from '@/remotion/compositions/marriage/MarriageInvitationWithBlueLights.tsx';
import MarriageInvitationWithChristmasCross from '@/remotion/compositions/marriage/MarriageInvitationWithChristmasCross.tsx';
import MarriageInvitationWithChristmasTree from '@/remotion/compositions/marriage/MarriageInvitationWithChristmasTree.tsx';
import MarriageInvitationWithColorfulBlasts from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulBlasts.tsx';
import MarriageInvitationWithColorfulFireworks from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulFireworks.tsx';
import MarriageInvitationWithColorfulLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulLeaves.tsx';
import MarriageInvitationWithDarkGreenAbstarct from '@/remotion/compositions/marriage/MarriageInvitationWithDarkGreenAbstarct.tsx';
import MarriageInvitationWithEagleAbstarct from '@/remotion/compositions/marriage/MarriageInvitationWithEagleAbstarct.tsx';
import MarriageInvitationWithFire from '@/remotion/compositions/marriage/MarriageInvitationWithFire.tsx';
import MarriageInvitationWithFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithFlowers';
import MarriageInvitationWithFlowersAndButterflys from '@/remotion/compositions/marriage/MarriageInvitationWithFlowersAndButterflys.tsx';
import MarriageInvitationWithFlowersAndLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithFlowersAndLeaves.tsx';
import MarriageInvitationWithFallingFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithFlowerVines.tsx';
import MarriageInvitationWithGarlands from '@/remotion/compositions/marriage/MarriageInvitationWithGarlands';
import MarriageInvitationWithGlowingHearts from '@/remotion/compositions/marriage/MarriageInvitationWithGlowingHearts.tsx';
import MarriageInvitationWithGoldenLights from '@/remotion/compositions/marriage/MarriageInvitationWithGoldenLights.tsx';
import MarriageInvitationWithGreenAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithGreenAbstract.tsx';
import MarriageInvitationWithHomeFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithHomeFlowers.tsx';
import MarriageInvitationWithLeavesAndGreenBG from '@/remotion/compositions/marriage/MarriageInvitationWithLeavesAndGreenBG.tsx';
import MarriageInvitationWithLights from '@/remotion/compositions/marriage/MarriageInvitationWithLights';
import MarriageInvitationWithMarronAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithMarronAbstract.tsx';
import MarriageInvitationWithMoonAndLights from '@/remotion/compositions/marriage/MarriageInvitationWithMoonAndLights.tsx';
import MarriageInvitationWithPurpleAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithPurpleAbstract.tsx';
import MarriageInvitationWithRaining from '@/remotion/compositions/marriage/MarriageInvitationWithRaining.tsx';
import MarriageInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithRotatingFlowers';
import MarriageInvitationWithRotatingRangoli from '@/remotion/compositions/marriage/MarriageInvitationWithRotatingRangoli.tsx';
import MarriageInvitationWithScrollingDiyas from '@/remotion/compositions/marriage/MarriageInvitationWithScrollingDiyas.tsx';
import MarriageInvitationWithSnowing from '@/remotion/compositions/marriage/MarriageInvitationWithSnowing.tsx';
import MarriageInvitationWithVintage from '@/remotion/compositions/marriage/MarriageInvitationWithVintage';
import MarriageInvitationWithVintageGreen from '@/remotion/compositions/marriage/MarriageInvitationWithVintageGreen.tsx';
import MarriageInvitationWithVintageOrange from '@/remotion/compositions/marriage/MarriageInvitationWithVintageOrange.tsx';
import MarriageInvitationWithYellowAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithYellowAbstract.tsx';
import MarriageInvitationWithYellowStarAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithYellowStarAbstract.tsx';
import LogoAnimation from '@/remotion/compositions/technology/LogoAnimation.tsx';
import SocialMediaCountAnimation from '@/remotion/compositions/technology/SocialMediaCountAnimation.tsx';
import { VIDEO_TYPES } from '@/shared/config/constants';
import { templateSkus } from '@/shared/services/TemplatesService.ts';

export const compositionMapping = {
  // Birthday wishes
  [`${templateSkus.birthdayBalloonsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithBalloons,
  [`${templateSkus.birthdayFlowersWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithFlowers,
  [`${templateSkus.birthdayVectorsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithVectors,
  [`${templateSkus.birthdaySpringsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithSprings,
  // Birthday invitations
  [`${templateSkus.birthdayAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractDoubleRotation,
  [`${templateSkus.birthdayAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractFlowers,
  [`${templateSkus.birthdayAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractLeaves,
  [`${templateSkus.birthdayBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBibleReading,
  [`${templateSkus.birthdayBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBlueAbstract,
  [`${templateSkus.birthdayBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBlueLights,
  [`${templateSkus.birthdayChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithChristmasCross,
  [`${templateSkus.birthdayChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithChristmasTree,
  [`${templateSkus.birthdayColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulBlasts,
  [`${templateSkus.birthdayColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulFireworks,
  [`${templateSkus.birthdayColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulLeaves,
  [`${templateSkus.birthdayDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithDarkGreenAbstract,
  [`${templateSkus.birthdayeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithEagleAbstract,
  [`${templateSkus.birthdayFireInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFire,
  [`${templateSkus.birthdayFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersAndButterflys,
  [`${templateSkus.birthdayFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersAndLeaves,
  [`${templateSkus.birthdayFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowers,
  [`${templateSkus.birthdayFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowerVines,
  [`${templateSkus.birthdayFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersWithGreenBackground,
  [`${templateSkus.birthdayGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGarlands,
  [`${templateSkus.birthdayGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGlowingHearts,
  [`${templateSkus.birthdayGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGoldenLights,
  [`${templateSkus.birthdayGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGreenAbstract,
  [`${templateSkus.birthdayHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithHomeFlowers,
  [`${templateSkus.birthdayLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithLeavesAndGreenBG,
  [`${templateSkus.birthdayLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithLights,
  [`${templateSkus.birthdayMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithMarronAbstract,
  [`${templateSkus.birthdayMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithMoonAndLights,
  [`${templateSkus.birthdayPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithPurpleAbstract,
  [`${templateSkus.birthdayRainingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRaining,
  [`${templateSkus.birthdayRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRotatingRangoli,
  [`${templateSkus.birthdayRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRotatingFlowers,
  [`${templateSkus.birthdayScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithScrollingDiyas,
  [`${templateSkus.birthdaySnowingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithSnowing,
  [`${templateSkus.birthdayVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintageGreen,
  [`${templateSkus.birthdayVintageInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintage,
  [`${templateSkus.birthdayVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintageOrange,
  [`${templateSkus.birthdayYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithYellowAbstract,
  [`${templateSkus.birthdayYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithYellowStarAbstract,
  // Marriage
  [`${templateSkus.marriageFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowers,
  [`${templateSkus.marriageVintageInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintage,
  [`${templateSkus.marriageLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithLights,
  [`${templateSkus.marriageRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRotatingFlowers,
  [`${templateSkus.marriageGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGarlands,
  [`${templateSkus.marriageFireInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFire,
  [`${templateSkus.marriageAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractFlowers,
  [`${templateSkus.marriageAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractLeaves,
  [`${templateSkus.marriageAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractDoubleRotation,
  [`${templateSkus.marriageBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBibleReading,
  [`${templateSkus.marriageBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBlueAbstract,
  [`${templateSkus.marriageBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBlueLights,
  [`${templateSkus.marriageSnowingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithSnowing,
  [`${templateSkus.marriageChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithChristmasTree,
  [`${templateSkus.marriageColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulBlasts,
  [`${templateSkus.marriageColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulFireworks,
  [`${templateSkus.marriageColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulLeaves,
  [`${templateSkus.marriageChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithChristmasCross,
  [`${templateSkus.marriageDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithDarkGreenAbstarct,
  [`${templateSkus.marriageeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithEagleAbstarct,
  [`${templateSkus.marriageFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFallingFlowers,
  [`${templateSkus.marriageFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowersAndButterflys,
  [`${templateSkus.marriageFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowersAndLeaves,
  [`${templateSkus.marriageFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationFlowersWithGreenBackground,
  [`${templateSkus.marriageGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGlowingHearts,
  [`${templateSkus.marriageGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGoldenLights,
  [`${templateSkus.marriageGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGreenAbstract,
  [`${templateSkus.marriageHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithHomeFlowers,
  [`${templateSkus.marriageLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithLeavesAndGreenBG,
  [`${templateSkus.marriageMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithMarronAbstract,
  [`${templateSkus.marriageMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithMoonAndLights,
  [`${templateSkus.marriagePurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithPurpleAbstract,
  [`${templateSkus.marriageRainingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRaining,
  [`${templateSkus.marriageRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRotatingRangoli,
  [`${templateSkus.marriageScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithScrollingDiyas,
  [`${templateSkus.marriageVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintageGreen,
  [`${templateSkus.marriageVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintageOrange,
  [`${templateSkus.marriageYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithYellowAbstract,
  [`${templateSkus.marriageYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithYellowStarAbstract,
  // Engagement
  [`${templateSkus.engagementAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractDoubleRotation,
  [`${templateSkus.engagementAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractFlowers,
  [`${templateSkus.engagementAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractLeaves,
  [`${templateSkus.engagementBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBibleReading,
  [`${templateSkus.engagementBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBlueAbstract,
  [`${templateSkus.engagementBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBlueLights,
  [`${templateSkus.engagementChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithChristmasCross,
  [`${templateSkus.engagementChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithChristmasTree,
  [`${templateSkus.engagementColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulBlasts,
  [`${templateSkus.engagementColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulFireworks,
  [`${templateSkus.engagementColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulLeaves,
  [`${templateSkus.engagementDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithDarkGreenAbstarct,
  [`${templateSkus.engagementeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithEagleAbstarct,
  [`${templateSkus.engagementFireInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFire,
  [`${templateSkus.engagementFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowerVines,
  [`${templateSkus.engagementFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowersAndButterflys,
  [`${templateSkus.engagementFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowersAndLeaves,
  [`${templateSkus.engagementFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowers,
  [`${templateSkus.engagementFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationFlowersWithGreenBackground,
  [`${templateSkus.engagementGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGlowingHearts,
  [`${templateSkus.engagementGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGoldenLights,
  [`${templateSkus.engagementGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGreenAbstract,
  [`${templateSkus.engagementHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithHomeFlowers,
  [`${templateSkus.engagementLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithLeavesAndGreenBG,
  [`${templateSkus.engagementLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithLights,
  [`${templateSkus.engagementMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithMarronAbstract,
  [`${templateSkus.engagementMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithMoonAndLights,
  [`${templateSkus.engagementPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithPurpleAbstract,
  [`${templateSkus.engagementRainingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRaining,
  [`${templateSkus.engagementRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRotatingRangoli,
  [`${templateSkus.engagementRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRotatingFlowers,
  [`${templateSkus.engagementScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithScrollingDiyas,
  [`${templateSkus.engagementSnowingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithSnowing,
  [`${templateSkus.engagementVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintageGreen,
  [`${templateSkus.engagementVintageInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintage,
  [`${templateSkus.engagementVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintageOrange,
  [`${templateSkus.engagementYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithYellowAbstract,
  [`${templateSkus.engagementYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithYellowStarAbstract,
  // House Warming
  [`${templateSkus.houseWarmingAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractDoubleRotation,
  [`${templateSkus.houseWarmingAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractFlowers,
  [`${templateSkus.houseWarmingAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractLeaves,
  [`${templateSkus.houseWarmingBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBibleReading,
  [`${templateSkus.houseWarmingBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBlueAbstract,
  [`${templateSkus.houseWarmingBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBlueLights,
  [`${templateSkus.houseWarmingChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithChristmasCross,
  [`${templateSkus.houseWarmingChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithChristmasTree,
  [`${templateSkus.houseWarmingColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulBlasts,
  [`${templateSkus.houseWarmingColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulFireworks,
  [`${templateSkus.houseWarmingColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulLeaves,
  [`${templateSkus.houseWarmingDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithDarkGreenAbstract,
  [`${templateSkus.houseWarmingeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithEagleAbstract,
  [`${templateSkus.houseWarmingFireInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFire,
  [`${templateSkus.houseWarmingFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersAndButterflys,
  [`${templateSkus.houseWarmingFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersAndLeaves,
  [`${templateSkus.houseWarmingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowers,
  [`${templateSkus.houseWarmingFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowerVines,
  [`${templateSkus.houseWarmingFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersWithGreenBackground,
  [`${templateSkus.houseWarmingGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGarlands,
  [`${templateSkus.houseWarmingGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGlowingHearts,
  [`${templateSkus.houseWarmingGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGoldenLights,
  [`${templateSkus.houseWarmingGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGreenAbstract,
  [`${templateSkus.houseWarmingHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithHomeFlowers,
  [`${templateSkus.houseWarmingLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithLeavesAndGreenBG,
  [`${templateSkus.houseWarmingLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithLights,
  [`${templateSkus.houseWarmingMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithMarronAbstract,
  [`${templateSkus.houseWarmingMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithMoonAndLights,
  [`${templateSkus.houseWarmingPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithPurpleAbstract,
  [`${templateSkus.houseWarmingRainingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRaining,
  [`${templateSkus.houseWarmingRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRotatingRangoli,
  [`${templateSkus.houseWarmingRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRotatingFlowers,
  [`${templateSkus.houseWarmingScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithScrollingDiyas,
  [`${templateSkus.houseWarmingSnowingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithSnowing,
  [`${templateSkus.houseWarmingVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintageGreen,
  [`${templateSkus.houseWarmingVintageInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintage,
  [`${templateSkus.houseWarmingVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintageOrange,
  [`${templateSkus.houseWarmingYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithYellowAbstract,
  [`${templateSkus.houseWarmingYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithYellowStarAbstract,
  // Festivals
  [`${templateSkus.festivalRakshaBandanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRakshaBandan,
  [`${templateSkus.festivalRamadanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRamadan,
  [`${templateSkus.festivalSnowmanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForSnowman,
  [`${templateSkus.festivalHoliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForHoli,
  [`${templateSkus.festivalIndependenceDayWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForIndependenceDay,
  [`${templateSkus.festivalDiwaliWithDiyasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwaliWithDiyas,
  [`${templateSkus.festivalDiwaliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwali,
  [`${templateSkus.festivalChristmasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmas,
  [`${templateSkus.festivalChristmasBallsWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithBalls,
  [`${templateSkus.festivalChristmasGiftBoxWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithGiftBox,
  [`${templateSkus.festivalChristmasGreenSnowWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithGreenSnow,
  [`${templateSkus.festivalChristmasLightsWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithLights,
  [`${templateSkus.festivalChristmasLightsSnowWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithLightsAndSnow,
  [`${templateSkus.festivalChristmasMoonWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithMoon,
  [`${templateSkus.festivalChristmasMountainsWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithMountains,
  [`${templateSkus.festivalChristmasSantaClausWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithSantaClaus,
  [`${templateSkus.festivalChristmasSnowWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithSnow,
  [`${templateSkus.festivalChristmasSnowingHousesWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithSnowingHouses,
  [`${templateSkus.festivalChristmasSnowmanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithSnowman,
  [`${templateSkus.festivalChristmasTreeWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmasWithTree,
  // Life Events
  [`${templateSkus.anniversaryBalloonsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithBalloons,
  [`${templateSkus.anniversaryFlowersWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithFlowers,
  [`${templateSkus.anniversaryVectorsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithVectors,
  [`${templateSkus.anniversarySpringsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithSprings,
  // Advertisements
  [`${templateSkus.productAdvertisement}-${VIDEO_TYPES.WEB}`]: ProductAdvertisement,
  [`${templateSkus.productAdvertisement2}-${VIDEO_TYPES.WEB}`]: ProductAdvertisement2,
  [`${templateSkus.productAdvertisement3}-${VIDEO_TYPES.WEB}`]: ProductAdvertisement3,
  [`${templateSkus.productAdvertisement4}-${VIDEO_TYPES.WEB}`]: ProductAdvertisement4,
  // Technology
  [`${templateSkus.logoAnimation}-${VIDEO_TYPES.WEB}`]: LogoAnimation,
  [`${templateSkus.logoAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoAnimation,
  [`${templateSkus.socialMediaCountAnimation}-${VIDEO_TYPES.WEB}`]: SocialMediaCountAnimation
};
