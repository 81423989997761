import { useState } from 'react';
import { Play } from 'lucide-react';
import { NButton, NInfiniteScroll, NLoading, useToast } from 'nayan';
import { formatInfiniteQueryData } from '@/shared/hooks/hooksUtils';
import { createAction, useAddActionMutation, useCommentsData } from '@/shared/services/ActionsService';
import { useUserStore } from '@/shared/stores/UserStore';
import { ACTION_TYPES } from '@/shared/types/types';
import { VideosCommentsItem } from './VideosCommentsItem';

interface Props {
  video: any;
}

export const VideosComments = (props: Props) => {
  const { video } = props;
  const toast = useToast();
  const [comment, setComment] = useState('');
  const userUuid = useUserStore(state => state.UUID);
  const { isLoading, data, fetchNextPage, hasNextPage, isFetching } = useCommentsData(video.uuid);
  const comments = formatInfiniteQueryData(data) || [];
  const addActionMutation = useAddActionMutation();

  const addComment = e => {
    e.preventDefault();
    if (!userUuid) {
      return toast('Please login to perform this action.');
    }
    if (!comment) {
      return toast('Comment should not be empty.');
    }
    const action = createAction(ACTION_TYPES.VIDEO_COMMENT, userUuid, video.userUuid, video.uuid, comment);
    addActionMutation.mutate(action);
    setComment('');
  };

  return (
    <div className="comments">
      <form onSubmit={addComment}>
        <div className="flex flex-row items-center bg-background border border-border rounded">
          <input
            className="mb-0 px-2 py-2 flex w-full focus:outline-none border-0 bg-background"
            name="comment"
            value={comment}
            placeholder="Type comment here..."
            onChange={event => setComment(event.target.value)}
          />
          <NButton className="bg-transparent hover:bg-transparent border-none pr-2 pl-2 z-0" type="submit" onClick={e => addComment(e)}>
            <Play size="1.8em" className="text-primary fill-primary" />
          </NButton>
        </div>
      </form>
      <div className="comments-list">
        {!!isLoading && <NLoading />}
        {!isLoading && !comments.length && <div className="p-10 text-center">No comments available.</div>}
        {!isLoading && !!comments.length && (
          <NInfiniteScroll
            next={() => !isFetching && fetchNextPage()}
            hasMore={!!hasNextPage}
            loader={<NLoading />}
            dataLength={comments.length}
            scrollThreshold={0.99}>
            <div className="w-full">
              {comments.map((comment: any) => (
                <VideosCommentsItem key={comment.uuid} comment={comment} video={video} />
              ))}
            </div>
          </NInfiniteScroll>
        )}
      </div>
    </div>
  );
};
