import React, { Suspense } from 'react';
import { NLoading } from 'nayan';
import { getTemplateMetaData } from '@/shared/services/TemplatesService.ts';
import { compositionMapping } from '@/ui/services/TemplatesMappingService.ts';
import { Player } from '@remotion/player';

interface Props {
  template: any;
  video: any;
  isFullScreen?: boolean;
}

const VideosPlayer = (props: Props) => {
  const { template, video, isFullScreen = false } = props;
  const type = video?.data?.type || 'web';
  const platform =
    !!template?.platforms?.length &&
    getTemplateMetaData(
      template.duration,
      template.platforms.some(platform => platform === 'web' || platform === 'square'),
      template.platforms.some(platform => platform === 'mobile'),
      template.platforms.some(platform => platform === 'square')
    );

  if (!template?.platforms || !video?.data) {
    return <div className="p-3 text-center">Video not available!</div>;
  }

  return (
    <Suspense fallback={<NLoading />}>
      <Player
        fps={24}
        loop
        controls
        autoPlay={true}
        alwaysShowControls
        inputProps={video.data}
        allowFullscreen={true}
        component={compositionMapping[template.sku + '-' + type]}
        className={
          isFullScreen ? '!w-full !max-w-full !max-h-full !w-screen !h-screen bg-card' : '!w-full !max-w-full !h-[250px] md:!h-[calc(100vh_-_109px)]'
        }
        durationInFrames={platform[type].compositionDuration}
        compositionWidth={platform[type].compositionWidth}
        compositionHeight={platform[type].compositionHeight}
        {...{ pauseWhenBuffering: true, playsInline: true }}
      />
    </Suspense>
  );
};

export default React.memo(VideosPlayer);
