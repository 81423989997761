import { differenceInDays } from 'date-fns';
import { NCard, NLoading, NTable } from 'nayan';
import { PRICING } from '@/shared/config/constants.ts';
import { formatQueryData } from '@/shared/hooks/hooksUtils';
import { DateService } from '@/shared/services/DateService';
import { priceConvertion, usePaymentsData } from '@/shared/services/PaymentsService';
import SubHeader from '../helpers/SubHeader';
import Payment from '../payments/Payment';

const AccountSubscriptions = () => {
  const { isLoading, data } = usePaymentsData();
  const payments = formatQueryData(data) || [];

  const columnDef = [
    { name: 'uuid', title: 'Payment ID' },
    {
      name: 'title',
      title: 'Description',
      component: ({ row }) => (!!row.title ? `Payment for ${row.title} video.` : `Payment for Inyter yearly subscription.`)
    },
    { name: 'amount', title: 'Amount', component: ({ row }) => `$${row.amount / 100}` },
    { name: 'status', title: 'Status', component: ({ row }) => (row.status === 'CREATED' ? 'FAILED' : row.status) },
    { name: 'created', title: 'Created', component: ({ row }) => DateService.format(row.created) }
  ];

  if (isLoading) {
    return <NLoading />;
  }

  const hasActiveSubscription = (days: number) => {
    return payments.some((item: any) => {
      return !item.videoUuid && item.status === 'SUCCESS' && differenceInDays(new Date(), new Date(item.created)) <= days;
    });
  };

  const getActiveSubscription = (days: number) => {
    const activeSubscription = payments.find((item: any) => {
      return !item.videoUuid && item.status === 'SUCCESS' && differenceInDays(new Date(), new Date(item.created)) <= days;
    });
    return activeSubscription && days - differenceInDays(new Date(), new Date(activeSubscription.created));
  };

  return (
    <div className="subscriptions">
      <SubHeader title="Subscriptions" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
        <NCard tabIndex={0} className={`col-span-1 p-3 border border-border`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Free</div>
            <div className="text-[18px]">
              ${PRICING.FREE}
              <span className="text-sm"> / video.</span>
            </div>
          </div>
          <div className="text-sm mb-3">
            Lifetime access includes invitations and wishes with watermarks and lower resolution. <br />
            <br />
            <span className="text-primary">No additional subscription needed for this.</span>
          </div>
        </NCard>
        <NCard tabIndex={0} className={`col-span-1 p-3 border border-border`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Pro Video</div>
            <div className="text-[18px]">
              ${PRICING.VIDEO}
              <span className="text-sm"> / video.</span>
            </div>
          </div>
          <div className="text-sm mb-3">
            Pay per video and get watermark-free, high-resolution invitations and wishes that are schedulable. <br />
            <br />
            <span className="text-primary">You can pay per video once you created a video.</span>
          </div>
        </NCard>
        <NCard tabIndex={0} className={`col-span-1 p-3 border border-border`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Pro Monthly</div>
            <div className="text-[18px]">
              ${PRICING.MONTHLY}
              <span className="text-sm"> / month.</span>
            </div>
          </div>
          <div className="text-sm mb-3">
            Pay for month and get watermark-free, high-resolution invitations and wishes that are schedulable.
            <br />
            <br />
            <span className="text-primary">
              {hasActiveSubscription(30) || hasActiveSubscription(365)
                ? 'Already subscribed, Subscription will end in ' + (getActiveSubscription(30) || getActiveSubscription(365)) + ' days.'
                : 'Subscribe premium to get additional features.'}
            </span>
          </div>
          <div className="text-right">
            <Payment amount={priceConvertion(PRICING.MONTHLY)} title="UPGRADE" disabled={hasActiveSubscription(30) || hasActiveSubscription(365)} />
          </div>
        </NCard>
        <NCard tabIndex={0} className={`col-span-1 p-3 border border-border`}>
          <div className="flex flex-row justify-between mb-3">
            <div className="text-[18px]">Pro Yearly</div>
            <div className="text-[18px]">
              ${PRICING.YEARLY}
              <span className="text-sm"> / year.</span>
            </div>
          </div>
          <div className="text-sm mb-3">
            Pay for year and get watermark-free, high-resolution invitations and wishes that are schedulable.
            <br />
            <br />
            <span className="text-primary">
              {hasActiveSubscription(365)
                ? 'Already subscribed, Subscription will end in ' + getActiveSubscription(365) + ' days.'
                : 'Subscribe premium to get additional features.'}
            </span>
          </div>
          <div className="text-right">
            <Payment amount={priceConvertion(PRICING.YEARLY)} title="UPGRADE" disabled={hasActiveSubscription(30) || hasActiveSubscription(365)} />
          </div>
          `
        </NCard>
      </div>

      <div className="text-lg mb-3">Payments History</div>
      {!!payments.length && <NTable className="bg-card w-full" columnDef={columnDef} data={payments} />}
      {!payments.length && <NCard className="p-5 text-center">No payments history available.</NCard>}
    </div>
  );
};

export default AccountSubscriptions;
