import { useEffect, useState } from 'react';
import { NButton, useToast } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CONFIG } from '@/shared/config/config';
import { socialLogin } from '@/shared/services/AuthService';
import { StorageService } from '@/ui/services/StorageService';
import { setUserFromStorage } from '@/ui/services/WebUtils';
import { QueryCache, useQueryClient } from '@tanstack/react-query';

const Social = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const restCache = new QueryCache();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [LoginSocialGoogle, setLoginSocialGoogle] = useState<any>(null);

  useEffect(() => {
    import('reactjs-social-login').then(module => {
      setLoginSocialGoogle(module.LoginSocialGoogle);
    });
  }, []);

  const handleLoginSuccess = async ({ data }) => {
    if (!data.name || !data.email) {
      toast('No sufficient permissions found, try again.');
    }
    setIsLoading(true);
    const user = { ...data, uuid: uuidv4(), email: data.email, name: data.name };
    return socialLogin(user)
      .then(async (res: any) => {
        StorageService.set('TOKEN', res.token);
        StorageService.set('UUID', res.uuid);
        StorageService.set('UNAME', res.name);
        StorageService.set('UEMAIL', res.email);
        StorageService.set('UABOUT', res.about);
        StorageService.set('UMOBILE', res.mobile);
        StorageService.set('UDOB', res.dob);
        StorageService.set('UPREMIUM', res.isPremium);
        await setUserFromStorage();
        restCache.clear();
        queryClient.clear();
        queryClient.removeQueries();
        await queryClient.invalidateQueries();
        navigate('/account', { replace: true });
      })
      .catch((error: any) => {
        toast(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleLoginError = error => {
    console.error('Login error:', error);
    toast('Something wrong while login, try again later.');
  };

  if (!LoginSocialGoogle) {
    return null;
  }

  return (
    <div className="social border-0 border-b border-border mb-5 relative">
      <LoginSocialGoogle scope="profile openid email" client_id={CONFIG.GOOGLE_CLIENT_ID} onResolve={handleLoginSuccess} onReject={handleLoginError}>
        <NButton
          className="w-full mb-5 bg-card hover:bg-card border-border text-text shadow"
          isLoading={isLoading}
          loadingText="Logging you with Google!">
          <img src="/google.webp" className="w-6 h-6 mr-1" alt="google" />
          Continue with Google!
        </NButton>
      </LoginSocialGoogle>
      <span className="text-xs absolute -mt-[8px] left-1/2 transform -translate-x-1/2 bg-border px-2 rounded-full">OR</span>
    </div>
  );
};

export default Social;
