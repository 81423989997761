export const HowItWorks = () => {
  return (
    <div className="how-it-works mb-16">
      <div className="text-xl text-center mb-5">HOW IT WORKS</div>
      <div className="text-base text-center max-w-[900px] mx-auto mb-5">
        With our easy-to-use platform, creating personalized video invitations and heartfelt wishes has never been simpler. Whether you're celebrating
        a birthday, wedding, anniversary, or any special occasion, our intuitive tools guide you through the process effortlessly. Just follow these
        simple steps to craft beautifully customized videos that leave a lasting impression on your loved ones.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-10">
        <div className="flex flex-col justify-center">
          <img src="/images/how-it-works/video-template.webp" className="w-[200px] h-auto mb-2 mx-auto" alt="Choose video template" loading="lazy" />
          <div className="text-base text-center mb-3">Choose Template</div>
          <div className="text-sm text-center leading-relaxed">
            Choose a template that matches your vision and message to create invitations and wishes.
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <img src="/images/how-it-works/video-details.webp" className="w-[200px] h-auto mb-2 mx-auto" alt="Choose video template" loading="lazy" />
          <div className="text-base text-center mb-3">Personalize Template</div>
          <div className="text-sm text-center leading-relaxed">
            Personalize the template with your own content, including text, images, audio and videos.
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <img src="/images/how-it-works/video-preview.webp" className="w-[200px] h-auto mb-2 mx-auto" alt="Choose video template" loading="lazy" />
          <div className="text-base text-center mb-3">Preview Video</div>
          <div className="text-sm text-center leading-relaxed">
            Preview your video to ensure it meets your expectations. Make any necessary adjustments.
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <img src="/images/how-it-works/video-payment.webp" className="w-[200px] h-auto mb-2 mx-auto" alt="Choose video template" loading="lazy" />
          <div className="text-base text-center mb-3">Pay & Download</div>
          <div className="text-sm text-center leading-relaxed">
            Proceed to payment to unlock additional features like Watermark free HD videos & Whatsapp Scheduling.
          </div>
        </div>
      </div>
    </div>
  );
};
