import { NLink } from 'nayan';
import { CONFIG } from '@/shared/config/config.ts';

const Downloads = () => {
  return (
    <section className="bg-card flex flex-col justify-center text">
      <div className="container mx-auto px-3 sm:px-0 mt-12 mb-12">
        <div className="text-xl text-center mb-5">DOWNLOADS</div>
        <div className="text-base text-center max-w-[900px] mx-auto mb-5">
          Experience the convenience of our feature-packed app on your mobile device. Whether you're using an Android or iOS device, you can easily
          download our app from the Google Play Store or Apple App Store and enjoy seamless access to all our services.
        </div>
        <div className="flex flex-row justify-center items-center">
          <NLink href={CONFIG.PLAY_STORE_URL}>
            <img src="/play-store.png" className="h-[80px] w-auto" alt="Inyter app google play store download" width="250" height="120" />
          </NLink>
          {/*<NLink href={CONFIG.APP_STORE_URL}>*/}
          {/*  <img src="/app-store.png" className="h-[53px] w-auto" alt="Inyter app google play store download" width="250" height="65" />*/}
          {/*</NLink>*/}
        </div>
      </div>
    </section>
  );
};

export default Downloads;
