import { z } from 'zod';
import { LANGUAGES, VIDEO_TYPES } from '../config/constants';
import { FORM_TYPES } from '../types/types';

export const marriageTags = [
  { sku: 'online-marriage-invitations', text: 'Online Marriage Invitations' },
  { sku: 'digital-wedding-invites', text: 'Digital Wedding Invites' },
  { sku: 'custom-marriage-invitations', text: 'Custom Marriage Invitations' },
  { sku: 'wedding-invitation-templates', text: 'Wedding Invitation Templates' },
  { sku: 'online-wedding-wishes', text: 'Online Wedding Wishes' },
  { sku: 'wedding-card-creation', text: 'Wedding Card Creation' },
  { sku: 'virtual-marriage-invitations', text: 'Virtual Marriage Invitations' },
  { sku: 'personalized-wedding-invites', text: 'Personalized Wedding Invites' },
  { sku: 'wedding-invitation-design', text: 'Wedding Invitation Design' },
  { sku: 'e-invitation-for-wedding', text: 'E-Invitation For Wedding' },
  { sku: 'wedding-wishes-online', text: 'Wedding Wishes Online' },
  { sku: 'create-wedding-invitations', text: 'Create Wedding Invitations' },
  { sku: 'wedding-invitation-maker', text: 'Wedding Invitation Maker' },
  { sku: 'diy-wedding-invitations', text: 'DIY Wedding Invitations' },
  { sku: 'wedding-invitation-messages', text: 'Wedding Invitation Messages' },
  { sku: 'e-card-wedding-wishes', text: 'E-Card Wedding Wishes' },
  { sku: 'wedding-greeting-cards-online', text: 'Wedding Greeting Cards Online' },
  { sku: 'online-wedding-party-invitations', text: 'Online Wedding Party Invitations' },
  { sku: 'digital-wedding-greetings', text: 'Digital Wedding Greetings' },
  { sku: 'printable-wedding-invitations', text: 'Printable Wedding Invitations' },
  { sku: 'customizable-wedding-invites', text: 'Customizable Wedding Invites' },
  { sku: 'interactive-wedding-invitations', text: 'Interactive Wedding Invitations' },
  { sku: 'wedding-invitation-cards', text: 'Wedding Invitation Cards' },
  { sku: 'unique-wedding-invitations', text: 'Unique Wedding Invitations' },
  { sku: 'wedding-invitation-ideas', text: 'Wedding Invitation Ideas' },
  { sku: 'wedding-party-e-invites', text: 'Wedding Party E-Invites' },
  { sku: 'personalized-wedding-cards', text: 'Personalized Wedding Cards' },
  { sku: 'virtual-wedding-greetings', text: 'Virtual Wedding Greetings' },
  { sku: 'wedding-wishes-design', text: 'Wedding Wishes Design' },
  { sku: 'custom-wedding-wishes', text: 'Custom Wedding Wishes' },
  { sku: 'online-wedding-party-cards', text: 'Online Wedding Party Cards' },
  { sku: 'wedding-invitation-customization', text: 'Wedding Invitation Customization' },
  { sku: 'digital-wedding-celebration-invites', text: 'Digital Wedding Celebration Invites' },
  { sku: 'wedding-invitation-online', text: 'Wedding Invitation Online' },
  { sku: 'online-wedding-invitation-services', text: 'Online Wedding Invitation Services' },
  { sku: 'wedding-invitation-rsvp', text: 'Wedding Invitation RSVP' },
  { sku: 'custom-digital-wedding-invites', text: 'Custom Digital Wedding Invites' },
  { sku: 'wedding-e-invite-design', text: 'Wedding E-Invite Design' },
  { sku: 'personalized-e-invitations', text: 'Personalized E-Invitations' },
  { sku: 'wedding-invitation-graphics', text: 'Wedding Invitation Graphics' },
  { sku: 'digital-wedding-cards', text: 'Digital Wedding Cards' },
  { sku: 'interactive-wedding-e-cards', text: 'Interactive Wedding E-Cards' },
  { sku: 'wedding-party-invitation-creation', text: 'Wedding Party Invitation Creation' },
  { sku: 'design-wedding-invitations-online', text: 'Design Wedding Invitations Online' },
  { sku: 'wedding-celebration-e-invites', text: 'Wedding Celebration E-Invites' },
  { sku: 'custom-wedding-e-cards', text: 'Custom Wedding E-Cards' },
  { sku: 'digital-wedding-party-invites', text: 'Digital Wedding Party Invites' },
  { sku: 'wedding-invitation-design-tools', text: 'Wedding Invitation Design Tools' },
  { sku: 'online-wedding-wishes-creation', text: 'Online Wedding Wishes Creation' },
  { sku: 'unique-digital-wedding-invitations', text: 'Unique Digital Wedding Invitations' },
  { sku: 'personalized-wedding-invitation-templates', text: 'Personalized Wedding Invitation Templates' },
  { sku: 'virtual-wedding-celebration-cards', text: 'Virtual Wedding Celebration Cards' },
  { sku: 'editable-wedding-invitations', text: 'Editable Wedding Invitations' },
  { sku: 'online-wedding-event-invitations', text: 'Online Wedding Event Invitations' },
  { sku: 'digital-wedding-invite-maker', text: 'Digital Wedding Invite Maker' },
  { sku: 'wedding-invitations-with-rsvp', text: 'Wedding Invitations With RSVP' },
  { sku: 'online-wedding-wishes-messages', text: 'Online Wedding Wishes Messages' },
  { sku: 'customizable-e-wedding-cards', text: 'Customizable E-Wedding Cards' },
  { sku: 'wedding-invitation-creation-platform', text: 'Wedding Invitation Creation Platform' },
  { sku: 'virtual-wedding-card-designs', text: 'Virtual Wedding Card Designs' },
  { sku: 'wedding-e-card-creation-tools', text: 'Wedding E-Card Creation Tools' },
  { sku: 'create-online-wedding-wishes', text: 'Create Online Wedding Wishes' },
  { sku: 'digital-wedding-party-cards', text: 'Digital Wedding Party Cards' },
  { sku: 'custom-wedding-greeting-e-cards', text: 'Custom Wedding Greeting E-Cards' },
  { sku: 'wedding-invitation-designs-online', text: 'Wedding Invitation Designs Online' },
  { sku: 'personalized-digital-wedding-wishes', text: 'Personalized Digital Wedding Wishes' },
  // Engagement
  { sku: 'engagement-video-wishes', text: 'Engagement Video Wishes' },
  { sku: 'create-engagement-congratulations-video', text: 'Create Engagement Congratulations Video' },
  { sku: 'personalized-engagement-video-messages', text: 'Personalized Engagement Video Messages' },
  { sku: 'engagement-video-greeting-card', text: 'Engagement Video Greeting Card' },
  { sku: 'custom-engagement-video-wishes', text: 'Custom Engagement Video Wishes' },
  { sku: 'video-messages-for-engagement-announcement', text: 'Video Messages For Engagement Announcement' },
  { sku: 'romantic-engagement-video-greetings', text: 'Romantic Engagement Video Greetings' },
  { sku: 'virtual-engagement-party-video', text: 'Virtual Engagement Party Video' },
  { sku: 'engagement-video-invitation', text: 'Engagement Video Invitation' },
  { sku: 'surprise-engagement-proposal-video', text: 'Surprise Engagement Proposal Video' }
];

export const marriageSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  surname: z.string(),
  message: z.string(),
  groomName: z.string(),
  groomImage: z.string(),
  groomFatherName: z.string(),
  groomMotherName: z.string(),
  brideName: z.string(),
  brideImage: z.string(),
  brideFatherName: z.string(),
  brideMotherName: z.string(),
  date: z.string(),
  venue: z.string()
});

export const marriageInvitationForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXT, key: 'welcomeMsg', label: 'Enter welcome message' },
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter title' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter Message' },
  { type: FORM_TYPES.FILE, key: 'groomImage', label: 'Choose Groom Image' },
  { type: FORM_TYPES.TEXT, key: 'groomName', label: 'Enter Groom Name' },
  { type: FORM_TYPES.TEXT, key: 'groomParents', label: 'Enter Groom parents details' },
  { type: FORM_TYPES.FILE, key: 'brideImage', label: 'Choose Bride Image' },
  { type: FORM_TYPES.TEXT, key: 'brideName', label: 'Enter Bride Name' },
  { type: FORM_TYPES.TEXT, key: 'brideParents', label: 'Enter Bride parents details' },
  { type: FORM_TYPES.TEXTAREA, key: 'date', label: 'Enter timing details' },
  { type: FORM_TYPES.TEXTAREA, key: 'venue', label: 'Enter venue details' }
];

export const marriageInvitationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      sonOf: 'Son of',
      daughterOf: 'Daughter of',
      weddingOn: 'WEDDING ON',
      venue: 'VENUE'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'OM GANESHAYA NAMAH',
      name: "Agarwal Family's Wedding Invitation",
      message: 'YOU ARE CORDIALLY INVITED TO CELEBRATE THE WEDDING OF',
      groomName: 'Manish Agarwal',
      groomImage: '',
      groomParents: 'Mr. Prem Nath Agarwal & Mrs. Sarojini',
      brideName: 'Anushka Sharma',
      brideImage: '',
      brideParents: 'Mr. Ajay Kumar Sharma & Mrs. Ashima',
      date: 'Thursday, December 21st, 2017',
      venue: 'Palace Grounds, Vasanth Nagar, Bangalore.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      sonOf: 'का बेटा',
      daughterOf: 'की बेटी',
      weddingOn: 'समय',
      venue: 'जगह'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Wedding Invitation',
      description: 'Create Wedding invitation and share with family and friends.',
      welcomeMsg: 'ॐ गणेशाय नमः',
      name: 'अग्रवाल परिवार का विवाह निमंत्रण',
      message: 'आप शादी का जश्न मनाने के लिए सादर आमंत्रित हैं.',
      groomName: 'मनीष अग्रवाल',
      groomImage: '',
      groomParents: 'श्री. प्रेम नाथ अग्रवाल & श्रीमती. सरोजिनी',
      brideName: 'अनुष्का शर्मा',
      brideImage: '',
      brideParents: 'श्री. अजय कुमार शर्मा & श्रीमती. आशिमा',
      date: 'गुरुवार, 21 दिसंबर 2017',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      sonOf: 'గారి కుమారుడు',
      daughterOf: 'గారి కుమార్తె',
      weddingOn: 'ముహూర్తం',
      venue: 'వేదిక'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'ఓం గణేశాయ నమః',
      name: 'అగర్వాల్ వారి పెండ్లి పిలుపు',
      message: 'వివాహ వేడుకలను జరుపుకోవడానికి మిమ్మల్ని హృదయపూర్వకంగా ఆహ్వానిస్తున్నాము',
      groomName: 'మనీష్ అగర్వాల్',
      groomImage: '',
      groomParents: 'శ్రీ. ప్రేమనాథ్ అగర్వాల్ & శ్రీమతి. సరోజినీ',
      brideName: 'అనుష్క శర్మ',
      brideImage: '',
      brideParents: 'శ్రీ. అజయ్ కుమార్ శర్మ & శ్రీమతి. అషిమా',
      date: 'గురువారం, 21 డిసెంబర్ 2017',
      venue: 'ప్యాలస్ గ్రౌండ్స్, వసంత్ నగర్, బెంగుళూరు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      sonOf: 'ಅವರ ಮಗ',
      daughterOf: 'ಅವರ ಮಗಳು',
      weddingOn: 'ವಿವಾಹ ಮುಹೂರ್ತ',
      venue: 'ವಿವಾಹ ಸ್ಥಳ'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'ಓಂ ಗಣೇಶಾಯ ನಮಃ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ಮದುವೆಯ ಆಮಂತ್ರಣ',
      message: 'ಮದುವೆಯನ್ನು ಆಚರಿಸಲು ನಾವು ನಿಮ್ಮನ್ನು ಹೃತ್ಪೂರ್ವಕವಾಗಿ ಆಹ್ವಾನಿಸುತ್ತೇವೆ',
      groomName: 'ಮನೀಶ್ ಅಗರ್ವಾಲ್',
      groomImage: '',
      groomParents: 'ಶ್ರೀ. ಪ್ರೇಮನಾಥ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಸರೋಜಿನಿ',
      brideName: 'ಅನುಷ್ಕಾ ಶರ್ಮಾ',
      brideImage: '',
      brideParents: 'ಶ್ರೀ. ಅಜಯ್ ಕುಮಾರ್ ಶರ್ಮಾ & ಶ್ರೀಮತಿ ಅಶಿಮಾ',
      date: 'ಗುರುವಾರ, 21 ಡಿಸೆಂಬರ್ 2017',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      sonOf: 'மகன்',
      daughterOf: 'மகள்',
      weddingOn: 'தேதி',
      venue: 'இடம்'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'ஓம் கணேசாய நம:',
      name: 'அகர்வால் குடும்பத்தின் திருமண அழைப்பிதல்',
      message: 'உங்கள் திருமணத்தை கொண்டாட அன்புடன் அழைக்கிறோம்.',
      groomName: 'மனிஷ் அகர்வால்',
      groomImage: '',
      groomParents: 'திரு. பிரேம் நாத் அகர்வால் & திருமதி. சரோஜினி',
      brideName: 'அனுஷ்கா ஷர்மா',
      brideImage: '',
      brideParents: 'திரு. அஜய் குமார் ஷர்மா & திருமதி அஷிமா',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      sonOf: 'മകൻ',
      daughterOf: 'യുടെ മകൾ',
      weddingOn: 'തീയതി',
      venue: 'വേദി'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'ഓം ഗണേശായ നമ:',
      name: 'അഗര്‍വാല്‍ കുടുംബത്തിന്റെ വിവാഹ നിമന്ത്രണം',
      message: 'നിങ്ങളെ വിവാഹാഘോഷത്തിന് സാദരം ക്ഷണിക്കുന്നു.',
      groomName: 'മനീഷ് അഗർവാൾ',
      groomImage: '',
      groomParents: 'ശ്രീ. പ്രേം നാഥ് അഗർവാൾ & ശ്രീമതി. സരോജിനി',
      brideName: 'അനുഷ്ക ശർമ്മ',
      brideImage: '',
      brideParents: 'ശ്രീ അജയ് കുമാർ ശർമ്മ & ശ്രീമതി അഷിമാ',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.'
    }
  }
};
export const marriageInvitationChristianLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      sonOf: 'Son of',
      daughterOf: 'Daughter of',
      weddingOn: 'WEDDING ON',
      venue: 'VENUE'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'HOLY CHRIST',
      name: "Agarwal Family's Wedding Invitation",
      message: 'YOU ARE CORDIALLY INVITED TO CELEBRATE THE WEDDING OF',
      groomName: 'Manish Agarwal',
      groomImage: '',
      groomParents: 'Mr. Prem Nath Agarwal & Mrs. Sarojini',
      brideName: 'Anushka Sharma',
      brideImage: '',
      brideParents: 'Mr. Ajay Kumar Sharma & Mrs. Ashima',
      date: 'Thursday, December 21st, 2017',
      venue: 'Palace Grounds, Vasanth Nagar, Bangalore.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      sonOf: 'का बेटा',
      daughterOf: 'की बेटी',
      weddingOn: 'समय',
      venue: 'जगह'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Wedding Invitation',
      description: 'Create Wedding invitation and share with family and friends.',
      welcomeMsg: 'पवित्र मसीह',
      name: 'अग्रवाल परिवार का विवाह निमंत्रण',
      message: 'आप शादी का जश्न मनाने के लिए सादर आमंत्रित हैं.',
      groomName: 'मनीष अग्रवाल',
      groomImage: '',
      groomParents: 'श्री. प्रेम नाथ अग्रवाल & श्रीमती. सरोजिनी',
      brideName: 'अनुष्का शर्मा',
      brideImage: '',
      brideParents: 'श्री. अजय कुमार शर्मा & श्रीमती. आशिमा',
      date: 'गुरुवार, 21 दिसंबर 2017',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      sonOf: 'గారి కుమారుడు',
      daughterOf: 'గారి కుమార్తె',
      weddingOn: 'ముహూర్తం',
      venue: 'వేదిక'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'పవిత్ర క్రీస్తు',
      name: 'అగర్వాల్ వారి పెండ్లి పిలుపు',
      message: 'వివాహ వేడుకలను జరుపుకోవడానికి మిమ్మల్ని హృదయపూర్వకంగా ఆహ్వానిస్తున్నాము',
      groomName: 'మనీష్ అగర్వాల్',
      groomImage: '',
      groomParents: 'శ్రీ. ప్రేమనాథ్ అగర్వాల్ & శ్రీమతి. సరోజినీ',
      brideName: 'అనుష్క శర్మ',
      brideImage: '',
      brideParents: 'శ్రీ. అజయ్ కుమార్ శర్మ & శ్రీమతి. అషిమా',
      date: 'గురువారం, 21 డిసెంబర్ 2017',
      venue: 'ప్యాలస్ గ్రౌండ్స్, వసంత్ నగర్, బెంగుళూరు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      sonOf: 'ಅವರ ಮಗ',
      daughterOf: 'ಅವರ ಮಗಳು',
      weddingOn: 'ವಿವಾಹ ಮುಹೂರ್ತ',
      venue: 'ವಿವಾಹ ಸ್ಥಳ'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'ಪವಿತ್ರ ಕ್ರಿಸ್ತ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ಮದುವೆಯ ಆಮಂತ್ರಣ',
      message: 'ಮದುವೆಯನ್ನು ಆಚರಿಸಲು ನಾವು ನಿಮ್ಮನ್ನು ಹೃತ್ಪೂರ್ವಕವಾಗಿ ಆಹ್ವಾನಿಸುತ್ತೇವೆ',
      groomName: 'ಮನೀಶ್ ಅಗರ್ವಾಲ್',
      groomImage: '',
      groomParents: 'ಶ್ರೀ. ಪ್ರೇಮನಾಥ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಸರೋಜಿನಿ',
      brideName: 'ಅನುಷ್ಕಾ ಶರ್ಮಾ',
      brideImage: '',
      brideParents: 'ಶ್ರೀ. ಅಜಯ್ ಕುಮಾರ್ ಶರ್ಮಾ & ಶ್ರೀಮತಿ ಅಶಿಮಾ',
      date: 'ಗುರುವಾರ, 21 ಡಿಸೆಂಬರ್ 2017',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      sonOf: 'மகன்',
      daughterOf: 'மகள்',
      weddingOn: 'தேதி',
      venue: 'இடம்'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'புனித கிறிஸ்து',
      name: 'அகர்வால் குடும்பத்தின் திருமண அழைப்பிதல்',
      message: 'உங்கள் திருமணத்தை கொண்டாட அன்புடன் அழைக்கிறோம்.',
      groomName: 'மனிஷ் அகர்வால்',
      groomImage: '',
      groomParents: 'திரு. பிரேம் நாத் அகர்வால் & திருமதி. சரோஜினி',
      brideName: 'அனுஷ்கா ஷர்மா',
      brideImage: '',
      brideParents: 'திரு. அஜய் குமார் ஷர்மா & திருமதி அஷிமா',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      sonOf: 'മകൻ',
      daughterOf: 'യുടെ മകൾ',
      weddingOn: 'തീയതി',
      venue: 'വേദി'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Marriage Invitation',
      description: 'Create Marriage invitation and share with family and friends.',
      welcomeMsg: 'പരിശുദ്ധ ക്രിസ്തു',
      name: 'അഗര്‍വാല്‍ കുടുംബത്തിന്റെ വിവാഹ നിമന്ത്രണം',
      message: 'നിങ്ങളെ വിവാഹാഘോഷത്തിന് സാദരം ക്ഷണിക്കുന്നു.',
      groomName: 'മനീഷ് അഗർവാൾ',
      groomImage: '',
      groomParents: 'ശ്രീ. പ്രേം നാഥ് അഗർവാൾ & ശ്രീമതി. സരോജിനി',
      brideName: 'അനുഷ്ക ശർമ്മ',
      brideImage: '',
      brideParents: 'ശ്രീ അജയ് കുമാർ ശർമ്മ & ശ്രീമതി അഷിമാ',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.'
    }
  }
};

export const engagementInvitationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      sonOf: 'Son of',
      daughterOf: 'Daughter of',
      weddingOn: 'ENGAGEMENT ON',
      venue: 'VENUE'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'OM GANESHAYA NAMAH',
      name: "Agarwal Family's Engagement Invitation",
      message: 'YOU ARE CORDIALLY INVITED TO CELEBRATE THE ENGAGEMENT OF',
      groomName: 'Manish Agarwal',
      groomImage: '',
      groomParents: 'Mr. Prem Nath Agarwal & Mrs. Sarojini',
      brideName: 'Anushka Sharma',
      brideImage: '',
      brideParents: 'Mr. Ajay Kumar Sharma & Mrs. Ashima',
      date: 'Thursday, December 21st, 2017',
      venue: 'Palace Grounds, Vasanth Nagar, Bangalore.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      sonOf: 'का बेटा',
      daughterOf: 'की बेटी',
      weddingOn: 'समय',
      venue: 'जगह'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ॐ गणेशाय नमः',
      name: 'अग्रवाल परिवार की सगाई का निमंत्रण',
      message: 'आप सगाई का जश्न मनाने के लिए सादर आमंत्रित हैं.',
      groomName: 'मनीष अग्रवाल',
      groomImage: '',
      groomParents: 'श्री. प्रेम नाथ अग्रवाल & श्रीमती. सरोजिनी',
      brideName: 'अनुष्का शर्मा',
      brideImage: '',
      brideParents: 'श्री. अजय कुमार शर्मा & श्रीमती. आशिमा',
      date: 'गुरुवार, 21 दिसंबर 2017',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      sonOf: 'గారి కుమారుడు',
      daughterOf: 'గారి కుమార్తె',
      weddingOn: 'ముహూర్తం',
      venue: 'వేదిక'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ఓం గణేశాయ నమః',
      name: 'అగర్వాల్ వారి నిశ్చితార్థ వేడుకలు',
      message: 'నిశ్చితార్థ వేడుకలను జరుపుకోవడానికి మిమ్మల్ని హృదయపూర్వకంగా ఆహ్వానిస్తున్నాము',
      groomName: 'మనీష్ అగర్వాల్',
      groomImage: '',
      groomParents: 'శ్రీ. ప్రేమనాథ్ అగర్వాల్ & శ్రీమతి. సరోజినీ',
      brideName: 'అనుష్క శర్మ',
      brideImage: '',
      brideParents: 'శ్రీ. అజయ్ కుమార్ శర్మ & శ్రీమతి. అషిమా',
      date: 'గురువారం, 21 డిసెంబర్ 2017',
      venue: 'ప్యాలస్ గ్రౌండ్స్, వసంత్ నగర్, బెంగుళూరు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      sonOf: 'ಅವರ ಮಗ',
      daughterOf: 'ಅವರ ಮಗಳು',
      weddingOn: 'ಮುಹೂರ್ತ',
      venue: 'ಸ್ಥಳ'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ಓಂ ಗಣೇಶಾಯ ನಮಃ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ನಿಶ್ಚಿತಾರ್ಥ ಸಮಾರಂಭಗಳು',
      message: 'ನಿಶ್ಚಿತಾರ್ಥ ಆಚರಿಸಲು ನಾವು ನಿಮ್ಮನ್ನು ಹೃತ್ಪೂರ್ವಕವಾಗಿ ಆಹ್ವಾನಿಸುತ್ತೇವೆ',
      groomName: 'ಮನೀಶ್ ಅಗರ್ವಾಲ್',
      groomImage: '',
      groomParents: 'ಶ್ರೀ. ಪ್ರೇಮನಾಥ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಸರೋಜಿನಿ',
      brideName: 'ಅನುಷ್ಕಾ ಶರ್ಮಾ',
      brideImage: '',
      brideParents: 'ಶ್ರೀ. ಅಜಯ್ ಕುಮಾರ್ ಶರ್ಮಾ & ಶ್ರೀಮತಿ ಅಶಿಮಾ',
      date: 'ಗುರುವಾರ, 21 ಡಿಸೆಂಬರ್ 2017',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      sonOf: 'மகன்',
      daughterOf: 'மகள்',
      weddingOn: 'தேதி',
      venue: 'இடம்'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ஓம் கணேசாய நம:',
      name: 'அகர்வால் குடும்பத்தின் திருமண நிமந்தனம்',
      message: 'நீங்கள் திருமணத் திருவிழாவை கொண்டாட அழைக்கப்படுகிறீர்கள்',
      groomName: 'மனிஷ் அகர்வால்',
      groomImage: '',
      groomParents: 'திரு. பிரேம் நாத் அகர்வால் & திருமதி. சரோஜினி',
      brideName: 'அனுஷ்கா ஷர்மா',
      brideImage: '',
      brideParents: 'திரு. அஜய் குமார் ஷர்மா & திருமதி அஷிமா',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      sonOf: 'മകൻ',
      daughterOf: 'യുടെ മകൾ',
      weddingOn: 'തീയതി',
      venue: 'വേദി'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ഓം ഗണേശായ നമ:',
      name: 'അഗർവാൾ കുടുംബത്തിന്റെ വിവാഹ ക്ഷണം',
      message: 'നിങ്ങൾ വിവാഹ നിശ്ചയ ചടങ്ങ് ആഘോഷിക്കാൻ ഹൃദയപൂർവ്വം ക്ഷണിക്കുന്നു',
      groomName: 'മനീഷ് അഗർവാൾ',
      groomImage: '',
      groomParents: 'ശ്രീ. പ്രേം നാഥ് അഗർവാൾ & ശ്രീമതി. സരോജിനി',
      brideName: 'അനുഷ്ക ശർമ്മ',
      brideImage: '',
      brideParents: 'ശ്രീ അജയ് കുമാർ ശർമ്മ & ശ്രീമതി അഷിമാ',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.'
    }
  }
};

export const engagementInvitationChristianLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      sonOf: 'Son of',
      daughterOf: 'Daughter of',
      weddingOn: 'ENGAGEMENT ON',
      venue: 'VENUE'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'HOLY CHRIST',
      name: "Agarwal Family's Engagement Invitation",
      message: 'YOU ARE CORDIALLY INVITED TO CELEBRATE THE ENGAGEMENT OF',
      groomName: 'Manish Agarwal',
      groomImage: '',
      groomParents: 'Mr. Prem Nath Agarwal & Mrs. Sarojini',
      brideName: 'Anushka Sharma',
      brideImage: '',
      brideParents: 'Mr. Ajay Kumar Sharma & Mrs. Ashima',
      date: 'Thursday, December 21st, 2017',
      venue: 'Palace Grounds, Vasanth Nagar, Bangalore.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      sonOf: 'का बेटा',
      daughterOf: 'की बेटी',
      weddingOn: 'समय',
      venue: 'जगह'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'पवित्र मसीह',
      name: 'अग्रवाल परिवार की सगाई का निमंत्रण',
      message: 'आप सगाई का जश्न मनाने के लिए सादर आमंत्रित हैं.',
      groomName: 'मनीष अग्रवाल',
      groomImage: '',
      groomParents: 'श्री. प्रेम नाथ अग्रवाल & श्रीमती. सरोजिनी',
      brideName: 'अनुष्का शर्मा',
      brideImage: '',
      brideParents: 'श्री. अजय कुमार शर्मा & श्रीमती. आशिमा',
      date: 'गुरुवार, 21 दिसंबर 2017',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      sonOf: 'గారి కుమారుడు',
      daughterOf: 'గారి కుమార్తె',
      weddingOn: 'ముహూర్తం',
      venue: 'వేదిక'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'పవిత్ర క్రీస్తు',
      name: 'అగర్వాల్ వారి నిశ్చితార్థ వేడుకలు',
      message: 'నిశ్చితార్థ వేడుకలను జరుపుకోవడానికి మిమ్మల్ని హృదయపూర్వకంగా ఆహ్వానిస్తున్నాము',
      groomName: 'మనీష్ అగర్వాల్',
      groomImage: '',
      groomParents: 'శ్రీ. ప్రేమనాథ్ అగర్వాల్ & శ్రీమతి. సరోజినీ',
      brideName: 'అనుష్క శర్మ',
      brideImage: '',
      brideParents: 'శ్రీ. అజయ్ కుమార్ శర్మ & శ్రీమతి. అషిమా',
      date: 'గురువారం, 21 డిసెంబర్ 2017',
      venue: 'ప్యాలస్ గ్రౌండ్స్, వసంత్ నగర్, బెంగుళూరు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      sonOf: 'ಅವರ ಮಗ',
      daughterOf: 'ಅವರ ಮಗಳು',
      weddingOn: 'ಮುಹೂರ್ತ',
      venue: 'ಸ್ಥಳ'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'ಪವಿತ್ರ ಕ್ರಿಸ್ತ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ನಿಶ್ಚಿತಾರ್ಥ ಸಮಾರಂಭಗಳು',
      message: 'ನಿಶ್ಚಿತಾರ್ಥ ಆಚರಿಸಲು ನಾವು ನಿಮ್ಮನ್ನು ಹೃತ್ಪೂರ್ವಕವಾಗಿ ಆಹ್ವಾನಿಸುತ್ತೇವೆ',
      groomName: 'ಮನೀಶ್ ಅಗರ್ವಾಲ್',
      groomImage: '',
      groomParents: 'ಶ್ರೀ. ಪ್ರೇಮನಾಥ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಸರೋಜಿನಿ',
      brideName: 'ಅನುಷ್ಕಾ ಶರ್ಮಾ',
      brideImage: '',
      brideParents: 'ಶ್ರೀ. ಅಜಯ್ ಕುಮಾರ್ ಶರ್ಮಾ & ಶ್ರೀಮತಿ ಅಶಿಮಾ',
      date: 'ಗುರುವಾರ, 21 ಡಿಸೆಂಬರ್ 2017',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      sonOf: 'மகன்',
      daughterOf: 'மகள்',
      weddingOn: 'தேதி',
      venue: 'இடம்'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'புனித கிறிஸ்து',
      name: 'அகர்வால் குடும்பத்தின் திருமண நிமந்தனம்',
      message: 'நீங்கள் திருமணத் திருவிழாவை கொண்டாட அழைக்கப்படுகிறீர்கள்',
      groomName: 'மனிஷ் அகர்வால்',
      groomImage: '',
      groomParents: 'திரு. பிரேம் நாத் அகர்வால் & திருமதி. சரோஜினி',
      brideName: 'அனுஷ்கா ஷர்மா',
      brideImage: '',
      brideParents: 'திரு. அஜய் குமார் ஷர்மா & திருமதி அஷிமா',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      sonOf: 'മകൻ',
      daughterOf: 'യുടെ മകൾ',
      weddingOn: 'തീയതി',
      venue: 'വേദി'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Engagement Invitation',
      description: 'Create Engagement invitation and share with family and friends.',
      welcomeMsg: 'പരിശുദ്ധ ക്രിസ്തു',
      name: 'അഗർവാൾ കുടുംബത്തിന്റെ വിവാഹ ക്ഷണം',
      message: 'നിങ്ങൾ വിവാഹ നിശ്ചയ ചടങ്ങ് ആഘോഷിക്കാൻ ഹൃദയപൂർവ്വം ക്ഷണിക്കുന്നു',
      groomName: 'മനീഷ് അഗർവാൾ',
      groomImage: '',
      groomParents: 'ശ്രീ. പ്രേം നാഥ് അഗർവാൾ & ശ്രീമതി. സരോജിനി',
      brideName: 'അനുഷ്ക ശർമ്മ',
      brideImage: '',
      brideParents: 'ശ്രീ അജയ് കുമാർ ശർമ്മ & ശ്രീമതി അഷിമാ',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.'
    }
  }
};
