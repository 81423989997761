import { NCard, NLink } from 'nayan';
import SubHeader from '@/ui/components/helpers/SubHeader';

const CancellationPolicies = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="Cancellation Policies" />
      <NCard className="p-3 mb-3">
        <h2 className="mb-5 text-base font-semibold">1. Overview:</h2>

        <div className="mb-5">
          Thank you for choosing Inyter for your online video invitations and wishes creation needs. We strive to offer the best service possible, but
          we understand that there may be times when you need to cancel your subscription. Please review our cancellation policy below.
        </div>

        <h2 className="mb-5 text-base font-semibold">2. Annual Subscription Cancellation:</h2>

        <div className="mb-5">
          <strong>Eligibility for Cancellation:</strong> You may cancel your annual subscription within 24 hours of your initial purchase. This is the
          only window during which cancellations are permitted for annual subscriptions.
        </div>
        <div className="mb-5">
          <strong>How to Cancel:</strong> To cancel your annual subscription within the 24-hour window, please contact our customer support team at{' '}
          <NLink href="mailto:hello@inyter.com" target="_blank">
            hello@inyter.com
          </NLink>
          . Be sure to include your account details and subscription information in your cancellation request.
        </div>
        <div className="mb-5">
          <strong>Refunds:</strong> If you cancel within the 72-hour period, you will receive a full refund. Please note that cancellations requested
          after this period will not be eligible for a refund.
        </div>

        <h2 className="mb-5 text-base font-semibold">3. Per Video Subscription Cancellation:</h2>

        <div className="mb-5">
          No Cancellation Policy: Per video subscriptions are non-cancellable and non-refundable. Once a per video subscription is purchased, it
          cannot be canceled or refunded.
        </div>
        <h2 className="mb-5 text-base font-semibold">4. Changes to the Policy:</h2>

        <div className="mb-5">
          Inyter reserves the right to update or modify this cancellation policy at any time. Any changes will be communicated to users via email or
          through our website. Continued use of our services constitutes acceptance of the revised policy.
        </div>

        <h2 className="mb-5 text-base font-semibold">5. Contact Us:</h2>

        <div className="mb-5">
          For any questions or assistance regarding cancellations, please contact our customer support team at{' '}
          <NLink href="mailto:hello@inyter.com" target="_blank">
            hello@inyter.com
          </NLink>{' '}
          or visit our help center on our website.
        </div>

        <div className="mb-5">Thank you for choosing Inyter. We appreciate your understanding and cooperation.</div>
      </NCard>
    </div>
  );
};

export default CancellationPolicies;
