import { useMemo } from 'react';
import { Series } from 'remotion';
import { z } from 'zod';
import weddingAudio from '@/remotion/assets/sounds/wedding.mp3';
import marriageVideo from '@/remotion/assets/videos/hanging-lights.mp4';
import { Wraper } from '@/remotion/helpers/Wraper';
import { marriageSchema } from '@/shared/schemas/MarriageSchema.ts';
import { getTemplateVideo, templates, templateSkus } from '@/shared/services/TemplatesService.ts';
import {
  MarriageBrideDetails,
  MarriageGroomDetails,
  MarriageMessage,
  MarriageTiming,
  MarriageTitle,
  MarriageVenue,
  MarriageWelcome
} from './MarriageComponents';

const MarriageInvitationWithLights: React.FC<z.infer<typeof marriageSchema>> = props => {
  const { language, color = '#c97629' } = props;
  const template = useMemo(() => getTemplateVideo('marriage', templateSkus.marriageLightsInvitation), []);
  const labels = template && templates.languages[template.languages][language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={marriageVideo} playbackRate={0.5} music={weddingAudio}>
      <Series>
        <Series.Sequence durationInFrames={60}>
          <MarriageWelcome props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageTitle props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageMessage props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageGroomDetails props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageBrideDetails props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageTiming props={props} labels={labels} color={color} />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageVenue props={props} labels={labels} color={color} />
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default MarriageInvitationWithLights;
