import { WATERMARK_POSITION } from '../config/constants';
import { adsAnimationForm, adsAnimationLanguages, adsAnimationSchema, advertisementTags } from '../schemas/AdvertisementsSchema';
import {
  birthdayChristianInvitationLanguages,
  birthdayInvitationLanguages,
  birthdayInvitationSchema,
  birthdayInviteForm,
  birthdaySchema,
  birthdayTags,
  birthdayWishesForm,
  birthdayWishesLanguages
} from '../schemas/BirthdaySchema';
import {
  festivalChristmasWishesLanguages,
  festivalDiwaliWishesLanguages,
  festivalHoliWishesLanguages,
  festivalIndependenceWishesLanguages,
  festivalRakhiWishesLanguages,
  festivalRamadanWishesLanguages,
  festivalSchema,
  festivalSnowmanWishesLanguages,
  festivalTags,
  festivalWishesForm
} from '../schemas/FestivalsSchema';
import {
  houseWarmingChristianInvitationLanguages,
  houseWarmingInvitationLanguages,
  houseWarmingInvitationSchema,
  houseWarmingInviteForm,
  houseWarmingTags
} from '../schemas/HouseWarmingSchema';
import { anniversaryWishesLanguages, lifeEventsSchema, lifeEventsTags, lifeEventsWishesForm } from '../schemas/LifeEventsSchema';
import {
  engagementInvitationChristianLanguages,
  engagementInvitationLanguages,
  marriageInvitationChristianLanguages,
  marriageInvitationForm,
  marriageInvitationLanguages,
  marriageSchema,
  marriageTags
} from '../schemas/MarriageSchema';
import {
  logoAnimationForm,
  logoAnimationLanguages,
  logoAnimationSchema,
  socialMediaCountAnimationForm,
  socialMediaCountAnimationLanguages,
  socialMediaCountAnimationSchema,
  technologyTags
} from '../schemas/TechnologySchema';

export const getTemplateMetaData = (duration: number, web = true, mobile = false, isSquare = false) => {
  let metadata = {};
  if (web) {
    metadata = {
      ...metadata,
      web: {
        compositionWidth: !isSquare ? 1280 : 720,
        compositionHeight: 720,
        compositionDuration: duration
      }
    };
  }
  if (mobile) {
    metadata = {
      ...metadata,
      mobile: {
        compositionWidth: 720,
        compositionHeight: !isSquare ? 1280 : 720,
        compositionDuration: duration
      }
    };
  }
  return metadata;
};

export const templateSkus = {
  // Birthday
  birthdayBalloonsWishes: 'birthday-wishes-with-balloons-animation',
  birthdayFlowersWishes: 'birthday-wishes-with-flowers-animation',
  birthdayVectorsWishes: 'birthday-wishes-with-vectors-animation',
  birthdaySpringsWishes: 'birthday-wishes-with-springs-animation',

  birthdayFlowersInvitation: 'birthday-invitation-with-flowers-animation',
  birthdayVintageInvitation: 'birthday-invitation-with-vintage-animation',
  birthdayLightsInvitation: 'birthday-invitation-with-lights-animation',
  birthdayRotatingFlowersInvitation: 'birthday-invitation-with-rotating-flowers-animation',
  birthdayGarlandsInvitation: 'birthday-invitation-with-garlands-animation',
  birthdayFireInvitation: 'birthday-invitation-with-fire-animation',
  birthdayAbstractFlowersInvitation: 'birthday-invitation-with-abstract-flowers-animation',
  birthdayAbstractLeavesInvitation: 'birthday-invitation-with-abstract-leaves-animation',
  birthdayAbstractDoubleRotationInvitation: 'birthday-invitation-with-abstract-double-rotation-animation',
  birthdayBibleReadingInvitation: 'birthday-invitation-with-bible-reading-animation',
  birthdayBlueAbstractInvitation: 'birthday-invitation-with-blue-abstract-animation',
  birthdayBlueLightsInvitation: 'birthday-invitation-with-blue-lights-animation',
  birthdaySnowingInvitation: 'birthday-invitation-with-snowing-animation',
  birthdayChristmasTreeInvitation: 'birthday-invitation-with-christmas-tree-animation',
  birthdayColorfulBlastsInvitation: 'birthday-invitation-with-colorful-blasts-animation',
  birthdayColorfulFireworksInvitation: 'birthday-invitation-with-colorful-fireworks-animation',
  birthdayColorfulLeavesInvitation: 'birthday-invitation-with-colorful-leaves-animation',
  birthdayChristmasCrossInvitation: 'birthday-invitation-with-christmas-cross-animation',
  birthdayDarkgreenAbstractInvitation: 'birthday-invitation-with-dark-green-abstract-animation',
  birthdayeagleAbstractInvitation: 'birthday-invitation-with-eagle-abstract-animation',
  birthdayFlowerVinesInvitation: 'birthday-invitation-with-flower-vines-animation',
  birthdayFlowersAndButterflysInvitation: 'birthday-invitation-with-flowers-and-butterflys-animation',
  birthdayFlowersAndLeavesInvitation: 'birthday-invitation-with-flowers-and-leaves-animation',
  birthdayFlowersWithGreenBGInvitation: 'birthday-invitation-with-flowers-with-green-background-animation',
  birthdayGlowingHeartsInvitation: 'birthday-invitation-with-glowing-hearts-animation',
  birthdayGoldLightsInvitation: 'birthday-invitation-with-gold-lights-animation',
  birthdayGreenAbstractInvitation: 'birthday-invitation-with-green-abstract-animation',
  birthdayHomeFlowersInvitation: 'birthday-invitation-with-home-flowers-animation',
  birthdayLeavesWithGreenBGInvitation: 'birthday-invitation-with-leaves-and-green-background-animation',
  birthdayMarronAbstractInvitation: 'birthday-invitation-with-maroon-abstract-animation',
  birthdayMoonAndLightsInvitation: 'birthday-invitation-with-moon-and-lights-animation',
  birthdayPurpleAbstractInvitation: 'birthday-invitation-with-purple-abstract-animation',
  birthdayRainingInvitation: 'birthday-invitation-with-raining-animation',
  birthdayRedRotatingRangoliInvitation: 'birthday-invitation-with-rotating-rangoli-animation',
  birthdayScrollingDiyasInvitation: 'birthday-invitation-with-scrolling-diyas-animation',
  birthdayVintageGreenInvitation: 'birthday-invitation-with-vintage-green-animation',
  birthdayVintageOrangeInvitation: 'birthday-invitation-with-vintage-orange-animation',
  birthdayYellowAbstractInvitation: 'birthday-invitation-with-yellow-abstract-animation',
  birthdayYellowStarAbstractInvitation: 'birthday-invitation-with-yellow-star-abstract-animation',
  // Marriage
  marriageFlowersInvitation: 'marriage-invitation-with-flowers-animation',
  marriageVintageInvitation: 'marriage-invitation-with-vintage-animation',
  marriageLightsInvitation: 'marriage-invitation-with-lights-animation',
  marriageRotatingFlowersInvitation: 'marriage-invitation-with-rotating-flowers-animation',
  marriageGarlandsInvitation: 'marriage-invitation-with-garlands-animation',
  marriageFireInvitation: 'marriage-invitation-with-fire-animation',
  marriageAbstractFlowersInvitation: 'marriage-invitation-with-abstract-flowers-animation',
  marriageAbstractLeavesInvitation: 'marriage-invitation-with-abstract-leaves-animation',
  marriageAbstractDoubleRotationInvitation: 'marriage-invitation-with-abstract-double-rotation-animation',
  marriageBibleReadingInvitation: 'marriage-invitation-with-bible-reading-animation',
  marriageBlueAbstractInvitation: 'marriage-invitation-with-blue-abstract-animation',
  marriageBlueLightsInvitation: 'marriage-invitation-with-blue-lights-animation',
  marriageSnowingInvitation: 'marriage-invitation-with-snowing-animation',
  marriageChristmasTreeInvitation: 'marriage-invitation-with-christmas-tree-animation',
  marriageColorfulBlastsInvitation: 'marriage-invitation-with-colorful-blasts-animation',
  marriageColorfulFireworksInvitation: 'marriage-invitation-with-colorful-fireworks-animation',
  marriageColorfulLeavesInvitation: 'marriage-invitation-with-colorful-leaves-animation',
  marriageChristmasCrossInvitation: 'marriage-invitation-with-christmas-cross-animation',
  marriageDarkgreenAbstractInvitation: 'marriage-invitation-with-dark-green-abstract-animation',
  marriageeagleAbstractInvitation: 'marriage-invitation-with-eagle-abstract-animation',
  marriageFlowerVinesInvitation: 'marriage-invitation-with-flower-vines-animation',
  marriageFlowersAndButterflysInvitation: 'marriage-invitation-with-flowers-and-butterflys-animation',
  marriageFlowersAndLeavesInvitation: 'marriage-invitation-with-flowers-and-leaves-animation',
  marriageFlowersWithGreenBGInvitation: 'marriage-invitation-with-flowers-with-green-background-animation',
  marriageGlowingHeartsInvitation: 'marriage-invitation-with-glowing-hearts-animation',
  marriageGoldLightsInvitation: 'marriage-invitation-with-gold-lights-animation',
  marriageGreenAbstractInvitation: 'marriage-invitation-with-green-abstract-animation',
  marriageHomeFlowersInvitation: 'marriage-invitation-with-home-flowers-animation',
  marriageLeavesWithGreenBGInvitation: 'marriage-invitation-with-leaves-and-green-background-animation',
  marriageMarronAbstractInvitation: 'marriage-invitation-with-maroon-abstract-animation',
  marriageMoonAndLightsInvitation: 'marriage-invitation-with-moon-and-lights-animation',
  marriagePurpleAbstractInvitation: 'marriage-invitation-with-purple-abstract-animation',
  marriageRainingInvitation: 'marriage-invitation-with-raining-animation',
  marriageRedRotatingRangoliInvitation: 'marriage-invitation-with-rotating-rangoli-animation',
  marriageScrollingDiyasInvitation: 'marriage-invitation-with-scrolling-diyas-animation',
  marriageVintageGreenInvitation: 'marriage-invitation-with-vintage-green-animation',
  marriageVintageOrangeInvitation: 'marriage-invitation-with-vintage-orange-animation',
  marriageYellowAbstractInvitation: 'marriage-invitation-with-yellow-abstract-animation',
  marriageYellowStarAbstractInvitation: 'marriage-invitation-with-yellow-star-abstract-animation',

  engagementFlowersInvitation: 'engagement-invitation-with-flowers-animation',
  engagementVintageInvitation: 'engagement-invitation-with-vintage-animation',
  engagementLightsInvitation: 'engagement-invitation-with-lights-animation',
  engagementRotatingFlowersInvitation: 'engagement-invitation-with-rotating-flowers-animation',
  engagementGarlandsInvitation: 'engagement-invitation-with-garlands-animation',
  engagementFireInvitation: 'engagement-invitation-with-fire-animation',
  engagementAbstractFlowersInvitation: 'engagement-invitation-with-abstract-flowers-animation',
  engagementAbstractLeavesInvitation: 'engagement-invitation-with-abstract-leaves-animation',
  engagementAbstractDoubleRotationInvitation: 'engagement-invitation-with-abstract-double-rotation-animation',
  engagementBibleReadingInvitation: 'engagement-invitation-with-bible-reading-animation',
  engagementBlueAbstractInvitation: 'engagement-invitation-with-blue-abstract-animation',
  engagementBlueLightsInvitation: 'engagement-invitation-with-blue-lights-animation',
  engagementSnowingInvitation: 'engagement-invitation-with-snowing-animation',
  engagementChristmasTreeInvitation: 'engagement-invitation-with-christmas-tree-animation',
  engagementColorfulBlastsInvitation: 'engagement-invitation-with-colorful-blasts-animation',
  engagementColorfulFireworksInvitation: 'engagement-invitation-with-colorful-fireworks-animation',
  engagementColorfulLeavesInvitation: 'engagement-invitation-with-colorful-leaves-animation',
  engagementChristmasCrossInvitation: 'engagement-invitation-with-christmas-cross-animation',
  engagementDarkgreenAbstractInvitation: 'engagement-invitation-with-dark-green-abstract-animation',
  engagementeagleAbstractInvitation: 'engagement-invitation-with-eagle-abstract-animation',
  engagementFlowerVinesInvitation: 'engagement-invitation-with-flower-vines-animation',
  engagementFlowersAndButterflysInvitation: 'engagement-invitation-with-flowers-and-butterflys-animation',
  engagementFlowersAndLeavesInvitation: 'engagement-invitation-with-flowers-and-leaves-animation',
  engagementFlowersWithGreenBGInvitation: 'engagement-invitation-with-flowers-with-green-background-animation',
  engagementGlowingHeartsInvitation: 'engagement-invitation-with-glowing-hearts-animation',
  engagementGoldLightsInvitation: 'engagement-invitation-with-gold-lights-animation',
  engagementGreenAbstractInvitation: 'engagement-invitation-with-green-abstract-animation',
  engagementHomeFlowersInvitation: 'engagement-invitation-with-home-flowers-animation',
  engagementLeavesWithGreenBGInvitation: 'engagement-invitation-with-leaves-and-green-background-animation',
  engagementMarronAbstractInvitation: 'engagement-invitation-with-maroon-abstract-animation',
  engagementMoonAndLightsInvitation: 'engagement-invitation-with-moon-and-lights-animation',
  engagementPurpleAbstractInvitation: 'engagement-invitation-with-purple-abstract-animation',
  engagementRainingInvitation: 'engagement-invitation-with-raining-animation',
  engagementRedRotatingRangoliInvitation: 'engagement-invitation-with-rotating-rangoli-animation',
  engagementScrollingDiyasInvitation: 'engagement-invitation-with-scrolling-diyas-animation',
  engagementVintageGreenInvitation: 'engagement-invitation-with-vintage-green-animation',
  engagementVintageOrangeInvitation: 'engagement-invitation-with-vintage-orange-animation',
  engagementYellowAbstractInvitation: 'engagement-invitation-with-yellow-abstract-animation',
  engagementYellowStarAbstractInvitation: 'engagement-invitation-with-yellow-star-abstract-animation',
  // House Warming
  houseWarmingFlowersInvitation: 'house-warming-invitation-with-flowers-animation',
  houseWarmingVintageInvitation: 'house-warming-invitation-with-vintage-animation',
  houseWarmingLightsInvitation: 'house-warming-invitation-with-lights-animation',
  houseWarmingRotatingFlowersInvitation: 'house-warming-invitation-with-rotating-flowers-animation',
  houseWarmingGarlandsInvitation: 'house-warming-invitation-with-garlands-animation',
  houseWarmingFireInvitation: 'house-warming-invitation-with-fire-animation',
  houseWarmingAbstractFlowersInvitation: 'house-warming-invitation-with-abstract-flowers-animation',
  houseWarmingAbstractLeavesInvitation: 'house-warming-invitation-with-abstract-leaves-animation',
  houseWarmingAbstractDoubleRotationInvitation: 'house-warming-invitation-with-abstract-double-rotation-animation',
  houseWarmingBibleReadingInvitation: 'house-warming-invitation-with-bible-reading-animation',
  houseWarmingBlueAbstractInvitation: 'house-warming-invitation-with-blue-abstract-animation',
  houseWarmingBlueLightsInvitation: 'house-warming-invitation-with-blue-lights-animation',
  houseWarmingSnowingInvitation: 'house-warming-invitation-with-snowing-animation',
  houseWarmingChristmasTreeInvitation: 'house-warming-invitation-with-christmas-tree-animation',
  houseWarmingColorfulBlastsInvitation: 'house-warming-invitation-with-colorful-blasts-animation',
  houseWarmingColorfulFireworksInvitation: 'house-warming-invitation-with-colorful-fireworks-animation',
  houseWarmingColorfulLeavesInvitation: 'house-warming-invitation-with-colorful-leaves-animation',
  houseWarmingChristmasCrossInvitation: 'house-warming-invitation-with-christmas-cross-animation',
  houseWarmingDarkgreenAbstractInvitation: 'house-warming-invitation-with-dark-green-abstract-animation',
  houseWarmingeagleAbstractInvitation: 'house-warming-invitation-with-eagle-abstract-animation',
  houseWarmingFlowerVinesInvitation: 'house-warming-invitation-with-flower-vines-animation',
  houseWarmingFlowersAndButterflysInvitation: 'house-warming-invitation-with-flowers-and-butterflys-animation',
  houseWarmingFlowersAndLeavesInvitation: 'house-warming-invitation-with-flowers-and-leaves-animation',
  houseWarmingFlowersWithGreenBGInvitation: 'house-warming-invitation-with-flowers-with-green-background-animation',
  houseWarmingGlowingHeartsInvitation: 'house-warming-invitation-with-glowing-hearts-animation',
  houseWarmingGoldLightsInvitation: 'house-warming-invitation-with-gold-lights-animation',
  houseWarmingGreenAbstractInvitation: 'house-warming-invitation-with-green-abstract-animation',
  houseWarmingHomeFlowersInvitation: 'house-warming-invitation-with-home-flowers-animation',
  houseWarmingLeavesWithGreenBGInvitation: 'house-warming-invitation-with-leaves-and-green-background-animation',
  houseWarmingMarronAbstractInvitation: 'house-warming-invitation-with-maroon-abstract-animation',
  houseWarmingMoonAndLightsInvitation: 'house-warming-invitation-with-moon-and-lights-animation',
  houseWarmingPurpleAbstractInvitation: 'house-warming-invitation-with-purple-abstract-animation',
  houseWarmingRainingInvitation: 'house-warming-invitation-with-raining-animation',
  houseWarmingRedRotatingRangoliInvitation: 'house-warming-invitation-with-rotating-rangoli-animation',
  houseWarmingScrollingDiyasInvitation: 'house-warming-invitation-with-scrolling-diyas-animation',
  houseWarmingVintageGreenInvitation: 'house-warming-invitation-with-vintage-green-animation',
  houseWarmingVintageOrangeInvitation: 'house-warming-invitation-with-vintage-orange-animation',
  houseWarmingYellowAbstractInvitation: 'house-warming-invitation-with-yellow-abstract-animation',
  houseWarmingYellowStarAbstractInvitation: 'house-warming-invitation-with-yellow-star-abstract-animation',
  // Festivals
  festivalRakshaBandanWishes: 'festival-wishes-for-raksha-bandan',
  festivalRamadanWishes: 'festival-wishes-for-ramadan',
  festivalChristmasWishes: 'festival-wishes-for-christmas',
  festivalChristmasBallsWishes: 'festival-wishes-for-christmas-with-balls',
  festivalChristmasGiftBoxWishes: 'festival-wishes-for-christmas-with-gift-box',
  festivalChristmasGreenSnowWishes: 'festival-wishes-for-christmas-with-green-snow',
  festivalChristmasLightsWishes: 'festival-wishes-for-christmas-with-lights',
  festivalChristmasLightsSnowWishes: 'festival-wishes-for-christmas-with-lights-and-snow',
  festivalChristmasMoonWishes: 'festival-wishes-for-christmas-with-moon',
  festivalChristmasMountainsWishes: 'festival-wishes-for-christmas-with-mountains',
  festivalChristmasSantaClausWishes: 'festival-wishes-for-christmas-with-santa-claus',
  festivalChristmasSnowWishes: 'festival-wishes-for-christmas-with-snow',
  festivalChristmasSnowingHousesWishes: 'festival-wishes-for-christmas-with-snowing-houses',
  festivalChristmasSnowmanWishes: 'festival-wishes-for-christmas-with-snowman',
  festivalChristmasTreeWishes: 'festival-wishes-for-christmas-with-tree',
  festivalDiwaliWishes: 'festival-wishes-for-diwali',
  festivalDiwaliWithDiyasWishes: 'festival-wishes-for-diwali-with-diyas',
  festivalHoliWishes: 'festival-wishes-for-holi',
  festivalSnowmanWishes: 'festival-wishes-for-snowman',
  festivalIndependenceDayWishes: 'festival-wishes-for-independence-day',
  // Life Events
  anniversaryBalloonsWishes: 'anniversary-wishes-with-balloons-animation',
  anniversaryFlowersWishes: 'anniversary-wishes-with-flowers-animation',
  anniversaryVectorsWishes: 'anniversary-wishes-with-vectors-animation',
  anniversarySpringsWishes: 'anniversary-wishes-with-springs-animation',
  // Advertisements
  productAdvertisement: 'product-advertisement-animation',
  productAdvertisement2: 'product-advertisement-animation-2',
  productAdvertisement3: 'product-advertisement-animation-3',
  productAdvertisement4: 'product-advertisement-animation-4',
  // Technology
  logoAnimation: 'logo-animation',
  socialMediaCountAnimation: 'social-media-count-animation'
};

const commonBirthdayInviteParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'birthday-invite',
  schema: 'birthday-invite',
  platforms: ['web'],
  duration: 290,
  languages: 'birthday-invite'
};

const commonBirthdayWishParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'birthday-wishes',
  schema: 'birthday-wishes',
  platforms: ['web'],
  duration: 290,
  languages: 'birthday-wishes'
};

export const birthdayVideos = [
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with yellow star abstract animation',
    description: 'Create Birthday invitations with yellow star abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayYellowStarAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with yellow abstract animation',
    description: 'Create Birthday invitations with yellow abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayYellowAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with vintage orange animation',
    description: 'Create Birthday invitations with vintage orange animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayVintageOrangeInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with vintage green animation',
    description: 'Create Birthday invitations with vintage green animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayVintageGreenInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with scrolling diyas animation',
    description: 'Create Birthday invitations with scrolling diyas animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.birthdayScrollingDiyasInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with rotating rangoli animation',
    description: 'Create Birthday invitations with rotating rangoli animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.birthdayRedRotatingRangoliInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with raining animation',
    description: 'Create Birthday invitations with raining animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayRainingInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with purple abstract animation',
    description: 'Create Birthday invitations with purple abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayPurpleAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with moon and lights animation',
    description: 'Create Birthday invitations with moon and lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayMoonAndLightsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with maroon abstract animation',
    description: 'Create Birthday invitations with maroon abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayMarronAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with leaves and green background background animation',
    description: 'Create Birthday invitations with leaves and green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayLeavesWithGreenBGInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with home flowers background animation',
    description: 'Create Birthday invitations with home flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayHomeFlowersInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with green abstract background animation',
    description: 'Create Birthday invitations with green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayGreenAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with gold lights background animation',
    description: 'Create Birthday invitations with gold lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayGoldLightsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with glowing hearts background animation',
    description: 'Create Birthday invitations with glowing hearts animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayGlowingHeartsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with Flowers with green background animation',
    description: 'Create Birthday invitations with flowers with green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFlowersWithGreenBGInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with Flowers and Leaves animation',
    description: 'Create Birthday invitations with flowers and leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFlowersAndLeavesInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with Flowers and Butterflys animation',
    description: 'Create Birthday invitations with flowers and butterflys animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.birthdayFlowersAndButterflysInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with Flower vines animation',
    description: 'Create Birthday invitations with flower vines animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFlowerVinesInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with fire animation',
    description: 'Create Birthday invitations with fire animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFireInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Abstract Flowers Animation',
    description: 'Create Birthday invitation with abstract flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayAbstractFlowersInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Abstract Leaves Animation',
    description: 'Create Birthday invitation with abstract leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayAbstractLeavesInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Abstract Double Rotation Animation',
    description: 'Create Birthday invitation with abstract double rotation animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayAbstractDoubleRotationInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Bible Reading Animation',
    description: 'Create Birthday invitation with bible reading animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayBibleReadingInvitation,
    languages: 'birthday-christian-invite'
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Blue Abstract Animation',
    description: 'Create Birthday invitation with blue abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayBlueAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Blue Lights Animation',
    description: 'Create Birthday invitation with blue lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayBlueLightsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Christmas Snowing Animation',
    description: 'Create Birthday invitation with christmas snowing animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdaySnowingInvitation,
    languages: 'birthday-christian-invite'
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Christmas Tree Animation',
    description: 'Create Birthday invitation with christmas tree animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.birthdayChristmasTreeInvitation,
    languages: 'birthday-christian-invite'
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Colorful Blast Animation',
    description: 'Create Birthday invitation with colorful blast animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayColorfulBlastsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Colorful Fireworks Animation',
    description: 'Create Birthday invitation with colorful fireworks animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayColorfulFireworksInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Colorful Leaves Animation',
    description: 'Create Birthday invitation with colorful leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayColorfulLeavesInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Christmas Cross Animation',
    description: 'Create Birthday invitation with christmas cross animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayChristmasCrossInvitation,
    languages: 'birthday-christian-invite'
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Dark Green Abstract Animation',
    description: 'Create Birthday invitation with dark green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdayDarkgreenAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Eagle Abstract Animation',
    description: 'Create Birthday invitation with eagle abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.birthdayeagleAbstractInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday Invitation with Flowers Animation',
    description: 'Create birthday invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFlowersInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with vintage animation',
    description: 'Create Birthday invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayVintageInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with lights animation',
    description: 'Create Birthday invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayLightsInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with rotating flowers animation',
    description: 'Create Marriage invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayRotatingFlowersInvitation
  },
  {
    ...commonBirthdayInviteParams,
    name: 'Birthday invitation with garlands animation',
    description: 'Create Birthday invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayGarlandsInvitation
  },
  {
    ...commonBirthdayWishParams,
    name: 'Birthday Wishes with Balloons Animation',
    description: 'Create birthday wishes with balloons animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayBalloonsWishes
  },
  {
    ...commonBirthdayWishParams,
    name: 'Birthday Wishes with Flowers Animation',
    description: 'Create birthday wishes with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayFlowersWishes
  },
  {
    ...commonBirthdayWishParams,
    name: 'Birthday Wishes with Vectors Animation',
    description: 'Create birthday wishes with vectors animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayVectorsWishes
  },
  {
    ...commonBirthdayWishParams,
    name: 'Birthday Wishes with Springs Animation',
    description: 'Create birthday wishes with springs animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdaySpringsWishes
  }
];

const commonMarriageVideosParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'marriage-invitation',
  schema: 'marriage-invitation',
  platforms: ['web'],
  duration: 450,
  languages: 'marriage-invitation'
};

const commonEngagementVideosParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'marriage-invitation',
  schema: 'marriage-invitation',
  platforms: ['web'],
  duration: 290,
  languages: 'engagement-invitation'
};

export const marriageVideos = [
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with yellow star abstract animation',
    description: 'Create Marriage invitations with yellow star abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageYellowStarAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with yellow abstract animation',
    description: 'Create Marriage invitations with yellow abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageYellowAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with vintage orange animation',
    description: 'Create Marriage invitations with vintage orange animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageVintageOrangeInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with vintage green animation',
    description: 'Create Marriage invitations with vintage green animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageVintageGreenInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with scrolling diyas animation',
    description: 'Create Marriage invitations with scrolling diyas animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageScrollingDiyasInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with rotating rangoli animation',
    description: 'Create Marriage invitations with rotating rangoli animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageRedRotatingRangoliInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with raining animation',
    description: 'Create Marriage invitations with raining animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageRainingInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with purple abstract animation',
    description: 'Create Marriage invitations with purple abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriagePurpleAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with moon and lights animation',
    description: 'Create Marriage invitations with moon and lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageMoonAndLightsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with maroon abstract animation',
    description: 'Create Marriage invitations with maroon abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageMarronAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with leaves and green background background animation',
    description: 'Create Marriage invitations with leaves and green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageLeavesWithGreenBGInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with home flowers background animation',
    description: 'Create Marriage invitations with home flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageHomeFlowersInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with green abstract background animation',
    description: 'Create Marriage invitations with green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageGreenAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with gold lights background animation',
    description: 'Create Marriage invitations with gold lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageGoldLightsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with glowing hearts background animation',
    description: 'Create Marriage invitations with glowing hearts animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageGlowingHeartsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with Flowers with green background animation',
    description: 'Create Marriage invitations with flowers with green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFlowersWithGreenBGInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with Flowers and Leaves animation',
    description: 'Create Marriage invitations with flowers and leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFlowersAndLeavesInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with Flowers and Butterflys animation',
    description: 'Create Marriage invitations with flowers and butterflys animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageFlowersAndButterflysInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with Flower vines animation',
    description: 'Create Marriage invitations with flower vines animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFlowerVinesInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with fire animation',
    description: 'Create Marriage invitations with fire animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFireInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Abstract Flowers Animation',
    description: 'Create marriage invitation with abstract flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageAbstractFlowersInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Abstract Leaves Animation',
    description: 'Create marriage invitation with abstract leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageAbstractLeavesInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Abstract Double Rotation Animation',
    description: 'Create marriage invitation with abstract double rotation animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageAbstractDoubleRotationInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Bible Reading Animation',
    description: 'Create marriage invitation with bible reading animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageBibleReadingInvitation,
    languages: 'marriage-christian-invitation'
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Blue Abstract Animation',
    description: 'Create marriage invitation with blue abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageBlueAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Blue Lights Animation',
    description: 'Create marriage invitation with blue lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageBlueLightsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Christmas Snowing Animation',
    description: 'Create marriage invitation with christmas snowing animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageSnowingInvitation,
    languages: 'marriage-christian-invitation'
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Christmas Tree Animation',
    description: 'Create marriage invitation with christmas tree animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageChristmasTreeInvitation,
    languages: 'marriage-christian-invitation'
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Colorful Blast Animation',
    description: 'Create marriage invitation with colorful blast animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageColorfulBlastsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Colorful Fireworks Animation',
    description: 'Create marriage invitation with colorful fireworks animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageColorfulFireworksInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Colorful Leaves Animation',
    description: 'Create marriage invitation with colorful leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.marriageColorfulLeavesInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Christmas Cross Animation',
    description: 'Create marriage invitation with christmas cross animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageChristmasCrossInvitation,
    languages: 'marriage-christian-invitation'
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Dark Green Abstract Animation',
    description: 'Create marriage invitation with dark green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageDarkgreenAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Eagle Abstract Animation',
    description: 'Create marriage invitation with eagle abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageeagleAbstractInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage Invitation with Flowers Animation',
    description: 'Create marriage invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFlowersInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with vintage animation',
    description: 'Create Marriage invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageVintageInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with lights animation',
    description: 'Create Marriage invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageLightsInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with flowers animation',
    description: 'Create Marriage invitations with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageRotatingFlowersInvitation
  },
  {
    ...commonMarriageVideosParams,
    name: 'Marriage invitation with garlands animation',
    description: 'Create Marriage invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageGarlandsInvitation
  },
  // Engagement videos
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with yellow star abstract animation',
    description: 'Create Engagement invitations with yellow star abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementYellowStarAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with yellow abstract animation',
    description: 'Create Engagement invitations with yellow abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementYellowAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with vintage orange animation',
    description: 'Create Engagement invitations with vintage orange animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementVintageOrangeInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with vintage green animation',
    description: 'Create Engagement invitations with vintage green animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementVintageGreenInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with scrolling diyas animation',
    description: 'Create Engagement invitations with scrolling diyas animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.engagementScrollingDiyasInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with rotating rangoli animation',
    description: 'Create Engagement invitations with rotating rangoli animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.engagementRedRotatingRangoliInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with raining animation',
    description: 'Create Engagement invitations with raining animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementRainingInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with purple abstract animation',
    description: 'Create Engagement invitations with purple abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementPurpleAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with moon and lights animation',
    description: 'Create Engagement invitations with moon and lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementMoonAndLightsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with maroon abstract animation',
    description: 'Create Engagement invitations with maroon abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementMarronAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with leaves and green background background animation',
    description: 'Create Engagement invitations with leaves and green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementLeavesWithGreenBGInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with home flowers background animation',
    description: 'Create Engagement invitations with home flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementHomeFlowersInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with green abstract background animation',
    description: 'Create Engagement invitations with green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementGreenAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with gold lights background animation',
    description: 'Create Engagement invitations with gold lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementGoldLightsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with glowing hearts background animation',
    description: 'Create Engagement invitations with glowing hearts animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementGlowingHeartsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with Flowers with green background animation',
    description: 'Create Engagement invitations with flowers with green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFlowersWithGreenBGInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with Flowers and Leaves animation',
    description: 'Create Engagement invitations with flowers and leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFlowersAndLeavesInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with Flowers and Butterflys animation',
    description: 'Create Engagement invitations with flowers and butterflys animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.engagementFlowersAndButterflysInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with Flower vines animation',
    description: 'Create Engagement invitations with flower vines animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFlowerVinesInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with fire animation',
    description: 'Create Engagement invitations with fire animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFireInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Abstract Flowers Animation',
    description: 'Create Engagement invitation with abstract flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementAbstractFlowersInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Abstract Leaves Animation',
    description: 'Create Engagement invitation with abstract leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementAbstractLeavesInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Abstract Double Rotation Animation',
    description: 'Create Engagement invitation with abstract double rotation animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementAbstractDoubleRotationInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Bible Reading Animation',
    description: 'Create Engagement invitation with bible reading animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.marriageBibleReadingInvitation,
    languages: 'engagement-christian-invitation'
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Blue Abstract Animation',
    description: 'Create Engagement invitation with blue abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementBlueAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Blue Lights Animation',
    description: 'Create Engagement invitation with blue lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementBlueLightsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Christmas Snowing Animation',
    description: 'Create Engagement invitation with christmas snowing animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageSnowingInvitation,
    languages: 'engagement-christian-invitation'
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Christmas Tree Animation',
    description: 'Create Engagement invitation with christmas tree animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.marriageChristmasTreeInvitation,
    languages: 'engagement-christian-invitation'
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Colorful Blast Animation',
    description: 'Create Engagement invitation with colorful blast animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementColorfulBlastsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Colorful Fireworks Animation',
    description: 'Create Engagement invitation with colorful fireworks animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementColorfulFireworksInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Colorful Leaves Animation',
    description: 'Create Engagement invitation with colorful leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.engagementColorfulLeavesInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Christmas Cross Animation',
    description: 'Create Engagement invitation with christmas cross animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageChristmasCrossInvitation,
    languages: 'engagement-christian-invitation'
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Dark Green Abstract Animation',
    description: 'Create Engagement invitation with dark green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.engagementDarkgreenAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Eagle Abstract Animation',
    description: 'Create Engagement invitation with eagle abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.engagementeagleAbstractInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement Invitation with Flowers Animation',
    description: 'Create Engagement invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFlowersInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with vintage animation',
    description: 'Create Engagement invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementVintageInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with lights animation',
    description: 'Create Engagement invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementLightsInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with rotating flowers animation',
    description: 'Create Engagement invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementRotatingFlowersInvitation
  },
  {
    ...commonEngagementVideosParams,
    name: 'Engagement invitation with garlands animation',
    description: 'Create Engagement invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementGarlandsInvitation
  }
];

const commonHouseWarmingVideosParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'house-warming-invitation',
  schema: 'house-warming-invitation',
  platforms: ['web'],
  duration: 290,
  languages: 'house-warming-invitation'
};

export const houseWarmingVideos = [
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with yellow star abstract animation',
    description: 'Create house warming invitations with yellow star abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingYellowStarAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with yellow abstract animation',
    description: 'Create house warming invitations with yellow abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingYellowAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with vintage orange animation',
    description: 'Create house warming invitations with vintage orange animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingVintageOrangeInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with vintage green animation',
    description: 'Create house warming invitations with vintage green animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingVintageGreenInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with scrolling diyas animation',
    description: 'Create house warming invitations with scrolling diyas animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.houseWarmingScrollingDiyasInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with rotating rangoli animation',
    description: 'Create house warming invitations with rotating rangoli animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.houseWarmingRedRotatingRangoliInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with raining animation',
    description: 'Create house warming invitations with raining animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingRainingInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with purple abstract animation',
    description: 'Create house warming invitations with purple abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingPurpleAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with moon and lights animation',
    description: 'Create house warming invitations with moon and lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingMoonAndLightsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with maroon abstract animation',
    description: 'Create house warming invitations with maroon abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingMarronAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with leaves and green background background animation',
    description: 'Create house warming invitations with leaves and green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingLeavesWithGreenBGInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with home flowers background animation',
    description: 'Create house warming invitations with home flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingHomeFlowersInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with green abstract background animation',
    description: 'Create house warming invitations with green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingGreenAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with gold lights background animation',
    description: 'Create house warming invitations with gold lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingGoldLightsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with glowing hearts background animation',
    description: 'Create house warming invitations with glowing hearts animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingGlowingHeartsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with Flowers with green background animation',
    description: 'Create house warming invitations with flowers with green background animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFlowersWithGreenBGInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with Flowers and Leaves animation',
    description: 'Create house warming invitations with flowers and leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFlowersAndLeavesInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with Flowers and Butterflys animation',
    description: 'Create house warming invitations with flowers and butterflys animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.houseWarmingFlowersAndButterflysInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with Flower vines animation',
    description: 'Create house warming invitations with flower vines animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFlowerVinesInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with fire animation',
    description: 'Create house warming invitations with fire animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFireInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Abstract Flowers Animation',
    description: 'Create house warming invitation with abstract flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingAbstractFlowersInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Abstract Leaves Animation',
    description: 'Create house warming invitation with abstract leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingAbstractLeavesInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Abstract Double Rotation Animation',
    description: 'Create house warming invitation with abstract double rotation animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingAbstractDoubleRotationInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Bible Reading Animation',
    description: 'Create house warming invitation with bible reading animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingBibleReadingInvitation,
    languages: 'house-warming-christian-invitation'
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Blue Abstract Animation',
    description: 'Create house warming invitation with blue abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingBlueAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Blue Lights Animation',
    description: 'Create house warming invitation with blue lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingBlueLightsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Christmas Snowing Animation',
    description: 'Create house warming invitation with christmas snowing animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingSnowingInvitation,
    languages: 'house-warming-christian-invitation'
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Christmas Tree Animation',
    description: 'Create house warming invitation with christmas tree animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.houseWarmingChristmasTreeInvitation,
    languages: 'house-warming-christian-invitation'
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Colorful Blast Animation',
    description: 'Create house warming invitation with colorful blast animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingColorfulBlastsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Colorful Fireworks Animation',
    description: 'Create house warming invitation with colorful fireworks animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingColorfulFireworksInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Colorful Leaves Animation',
    description: 'Create house warming invitation with colorful leaves animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.houseWarmingColorfulLeavesInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Christmas Cross Animation',
    description: 'Create house warming invitation with christmas cross animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingChristmasCrossInvitation,
    languages: 'house-warming-christian-invitation'
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Dark Green Abstract Animation',
    description: 'Create house warming invitation with dark green abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.houseWarmingDarkgreenAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Eagle Abstract Animation',
    description: 'Create house warming invitation with eagle abstract animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.houseWarmingeagleAbstractInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming Invitation with Flowers Animation',
    description: 'Create house warming invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFlowersInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with vintage animation',
    description: 'Create house warming invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingVintageInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with lights animation',
    description: 'Create house warming invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingLightsInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with rotating flowers animation',
    description: 'Create house warming invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingRotatingFlowersInvitation
  },
  {
    ...commonHouseWarmingVideosParams,
    name: 'House Warming invitation with garlands animation',
    description: 'Create Birthday invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingGarlandsInvitation
  }
];

const commonFestivalWishParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'festival-wishes',
  schema: 'festival-wishes',
  platforms: ['web'],
  duration: 290
};

export const festivalVideos = [
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Raksha Bandhan',
    description: 'Create festival wishes for Raksha bandhan for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.festivalRakshaBandanWishes,
    languages: 'festival-rakhi-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Holi',
    description: 'Create festival wishes for Holi for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalHoliWishes,
    languages: 'festival-holi-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Diwali',
    description: 'Create festival wishes for Diwali for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.festivalDiwaliWishes,
    languages: 'festival-diwali-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Diwali with diyas',
    description: "Create festival wishes for Diwali with diya's for your friends and family.",
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.festivalDiwaliWithDiyasWishes,
    languages: 'festival-diwali-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Ramadan',
    description: 'Create festival wishes for Ramadan for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.festivalRamadanWishes,
    languages: 'festival-ramadan-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Independence Day',
    description: 'Create festival wishes for Independence Day for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.festivalIndependenceDayWishes,
    languages: 'festival-independence-day-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Snowman',
    description: 'Create festival wishes for Snowman for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.festivalSnowmanWishes,
    languages: 'festival-snowman-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Balls',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.festivalChristmasBallsWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Gift Box',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasGiftBoxWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Green Snow',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.festivalChristmasGreenSnowWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Lights',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasLightsWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Lights and Snow',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.festivalChristmasLightsSnowWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Moon',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasMoonWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Mountains',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasMountainsWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Santa Claus',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.festivalChristmasSantaClausWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Snow',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasSnowWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Snowing Houses',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasSnowingHousesWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Snowman',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasSnowmanWishes,
    languages: 'festival-christmas-wishes'
  },
  {
    ...commonFestivalWishParams,
    name: 'Festival Wishes for Christmas with Tree',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasTreeWishes,
    languages: 'festival-christmas-wishes'
  }
];

export const kidsVideos = [...birthdayVideos];

const commonLifeEventsParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'life-events-wishes',
  schema: 'life-events-wishes',
  platforms: ['web'],
  duration: 290,
  languages: 'anniversary-wishes'
};

export const lifeEventsVideos = [
  {
    ...commonLifeEventsParams,
    name: 'Anniversary Wishes with Balloons Animation',
    description: 'Create anniversary wishes with balloons animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.anniversaryBalloonsWishes
  },
  {
    ...commonLifeEventsParams,
    name: 'Anniversary Wishes with Flowers Animation',
    description: 'Create anniversary wishes with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.anniversaryFlowersWishes
  },
  {
    ...commonLifeEventsParams,
    name: 'Anniversary Wishes with Vectors Animation',
    description: 'Create anniversary wishes with vectors animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.anniversaryVectorsWishes
  },
  {
    ...commonLifeEventsParams,
    name: 'Anniversary Wishes with Springs Animation',
    description: 'Create anniversary wishes with springs animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.anniversarySpringsWishes
  }
];

const commonAdvertisementParams = {
  watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
  form: 'ads-animation',
  schema: 'ads-animation',
  platforms: ['square'],
  duration: 120,
  languages: 'ads-animation'
};

export const advertisementVideos = [
  {
    ...commonAdvertisementParams,
    name: 'Product Advertisement Animation',
    description: 'Create product advertisement animations based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.MIDDLERIGHT,
    sku: templateSkus.productAdvertisement
  },
  {
    ...commonAdvertisementParams,
    name: 'Product Advertisement Animation 2',
    description: 'Create product advertisement animations based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.MIDDLERIGHT,
    sku: templateSkus.productAdvertisement2
  },
  {
    ...commonAdvertisementParams,
    name: 'Product Advertisement Animation 3',
    description: 'Create product advertisement animations based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.MIDDLERIGHT,
    sku: templateSkus.productAdvertisement3
  },
  {
    ...commonAdvertisementParams,
    name: 'Product Advertisement Animation 4',
    description: 'Create product advertisement animations based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.productAdvertisement4,
    platforms: ['web'],
    duration: 120
  }
];

export const technologyVideos = [
  {
    name: 'Social Media Count Animation',
    description: 'Create social media count animation video based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.socialMediaCountAnimation,
    form: 'social-media-counter',
    schema: 'social-media-counter',
    platforms: ['web'],
    duration: 120,
    languages: 'social-media-counter'
  },
  {
    name: 'Logo Animation',
    description: 'Create logo animation based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.logoAnimation,
    form: 'logo-animation',
    schema: 'logo-animation',
    platforms: ['web', 'mobile'],
    duration: 120,
    languages: 'logo-animation'
  }
];

export const tagsMapping = {
  birthday: birthdayTags,
  marriage: marriageTags,
  'house-warming': houseWarmingTags,
  festivals: festivalTags,
  kids: [...birthdayTags],
  'life-events': lifeEventsTags,
  advertisements: advertisementTags,
  technology: technologyTags
};

export const schemaMapping = {
  'birthday-invite': birthdayInvitationSchema,
  'birthday-wishes': birthdaySchema,
  'marriage-invitation': marriageSchema,
  'house-warming-invitation': houseWarmingInvitationSchema,
  'festival-wishes': festivalSchema,
  'life-events-wishes': lifeEventsSchema,
  'ads-animation': adsAnimationSchema,
  'social-media-counter': socialMediaCountAnimationSchema,
  'logo-animation': logoAnimationSchema
};

export const templates = {
  forms: {
    'birthday-invite': birthdayInviteForm,
    'birthday-wishes': birthdayWishesForm,
    'marriage-invitation': marriageInvitationForm,
    'house-warming-invitation': houseWarmingInviteForm,
    'festival-wishes': festivalWishesForm,
    'life-events-wishes': lifeEventsWishesForm,
    'ads-animation': adsAnimationForm,
    'social-media-counter': socialMediaCountAnimationForm,
    'logo-animation': logoAnimationForm
  },
  languages: {
    'birthday-invite': birthdayInvitationLanguages,
    'birthday-christian-invite': birthdayChristianInvitationLanguages,
    'birthday-wishes': birthdayWishesLanguages,
    'marriage-invitation': marriageInvitationLanguages,
    'marriage-christian-invitation': marriageInvitationChristianLanguages,
    'engagement-invitation': engagementInvitationLanguages,
    'engagement-christian-invitation': engagementInvitationChristianLanguages,
    'house-warming-invitation': houseWarmingInvitationLanguages,
    'house-warming-christian-invitation': houseWarmingChristianInvitationLanguages,
    'festival-rakhi-wishes': festivalRakhiWishesLanguages,
    'festival-holi-wishes': festivalHoliWishesLanguages,
    'festival-diwali-wishes': festivalDiwaliWishesLanguages,
    'festival-ramadan-wishes': festivalRamadanWishesLanguages,
    'festival-independence-day-wishes': festivalIndependenceWishesLanguages,
    'festival-snowman-wishes': festivalSnowmanWishesLanguages,
    'festival-christmas-wishes': festivalChristmasWishesLanguages,
    'anniversary-wishes': anniversaryWishesLanguages,
    'ads-animation': adsAnimationLanguages,
    'social-media-counter': socialMediaCountAnimationLanguages,
    'logo-animation': logoAnimationLanguages
  },
  list: [
    {
      name: 'Birthday',
      sku: 'birthday',
      details:
        "Birthday wishes and invitations video templates offer a fun and creative way to celebrate special occasions. These templates come pre-designed with vibrant animations, music, and custom text options, allowing users to personalize heartfelt messages and event details. Perfect for sending digital birthday greetings or inviting guests to parties, they save time while delivering visually stunning and memorable content. Whether for kids' birthdays, milestone celebrations, or surprise parties, video templates add a unique touch to the festivities with ease and style.",
      videos: birthdayVideos
    },
    {
      name: 'Marriage',
      sku: 'marriage',
      details:
        'Marriage and engagement invitation video templates provide a modern and elegant way to announce your special day. These templates are pre-designed with beautiful animations, romantic themes, and customizable options for adding personal details like names, dates, and venue information. Ideal for sharing online, these videos offer a captivating and memorable alternative to traditional invitations, perfect for setting the tone of your wedding or engagement event. Whether formal or casual, these templates help you create stunning, personalized invitations effortlessly.',
      videos: marriageVideos
    },
    {
      name: 'House Warming',
      sku: 'house-warming',
      details:
        'Housewarming invitation video templates offer a stylish and convenient way to invite friends and family to celebrate your new home. With customizable designs featuring warm themes, cozy animations, and editable text, these templates make it easy to personalize details like the date, time, and address. Perfect for sharing digitally, these videos add a creative and welcoming touch to your housewarming celebration, helping you set the right mood for this exciting milestone.',
      videos: houseWarmingVideos
    },
    {
      name: 'Festivals',
      sku: 'festivals',
      details:
        'Festival wishes video templates are a vibrant and festive way to send greetings during special occasions like Diwali, Christmas, Eid, and more. These templates feature lively animations, colorful themes, and customizable options for personal messages, making it easy to create and share joyful greetings. Perfect for spreading holiday cheer, they add a unique, visually stunning touch to your wishes, ensuring your greetings stand out and bring smiles to loved ones.',
      videos: festivalVideos
    },
    {
      name: 'Kids',
      sku: 'kids',
      details:
        "Kids' function video templates, such as for birthdays, dhoti functions, and half saree ceremonies, provide a playful and vibrant way to celebrate special milestones. These templates feature charming animations, colorful designs, and customizable text, allowing you to personalize event details and messages. Perfect for creating digital invitations or video highlights, they capture the joy and tradition of these memorable moments with ease and creativity. Ideal for sharing with friends and family, these templates add a festive and heartwarming touch to your child’s special day.",
      videos: kidsVideos
    },
    {
      name: 'Life Events',
      sku: 'life-events',
      details:
        "Life event video templates, such as for anniversaries, retirements, and memorials, offer a heartfelt way to celebrate and honor significant milestones. These templates come with elegant designs, soothing animations, and customizable options to add personal details like messages, names, and dates. Perfect for creating memorable digital tributes, invitations, or celebration videos, they help capture the emotions of these special moments, whether commemorating years of togetherness, a career's conclusion, or paying tribute to a loved one’s memory.",
      videos: lifeEventsVideos
    },
    {
      name: 'Advertisements',
      sku: 'advertisements',
      details:
        'Animating advertisements for social media and product promotion video templates offer a dynamic and eye-catching way to market your brand. These templates feature bold animations, engaging visuals, and customizable text, making it easy to showcase your products, services, or special offers. Ideal for platforms like Instagram, Facebook, and YouTube, they help capture attention quickly and convey your message effectively. With professional designs and seamless transitions, these templates are perfect for creating promotional ads that boost visibility and drive engagement with your target audience.',
      videos: advertisementVideos
    },
    {
      name: 'Technology',
      sku: 'technology',
      details:
        'Animating logos, YouTube follower achievements, and Twitter followers video templates provide an engaging way to highlight milestones and boost brand visibility. These templates feature dynamic animations and sleek designs, perfect for showcasing growing follower counts or celebrating social media achievements. With customizable options, you can personalize your brand’s logo or follower milestones, making them ideal for social media shoutouts, promotional videos, or celebratory posts. These templates add a professional and captivating touch to your content, enhancing engagement and recognition across platforms.',
      videos: technologyVideos
    }
  ]
};

export const getTemplates = () => {
  return templates.list.map(t => ({ name: t.name, sku: t.sku, details: t.details }));
};

export const getTemplate = (type: string) => {
  return templates.list.find(t => t.sku === type) as any;
};

export const getTemplateByTag = (tag: string) => {
  for (const [category, items] of Object.entries(tagsMapping)) {
    if (items.some(item => item.sku === tag)) {
      return templates.list.find(t => t.sku === category) as any;
    }
  }
  return null;
};

export const getTemplateVideo = (type: string, videoSku: any) => {
  const template = templates.list.find(t => t.sku === type);
  const video = template?.videos?.find((v: any) => v.sku === videoSku);
  if (!template || !video) return null;
  return { ...video, template: { name: template.name, sku: template.sku, tags: tagsMapping[template.sku] } };
};
