import { z } from 'zod';
import { LANGUAGES, VIDEO_TYPES } from '../config/constants';
import { FORM_TYPES } from '../types/types';

export const festivalTags = [
  { sku: 'indian-festivals-wishes', text: 'Indian Festivals Wishes' },
  { sku: 'diwali-greetings', text: 'Diwali Greetings' },
  { sku: 'holi-wishes', text: 'Holi Wishes' },
  { sku: 'navratri-greetings', text: 'Navratri Greetings' },
  { sku: 'eid-mubarak-wishes', text: 'Eid Mubarak Wishes' },
  { sku: 'ganesh-chaturthi-wishes', text: 'Ganesh Chaturthi Wishes' },
  { sku: 'raksha-bandhan-wishes', text: 'Raksha Bandhan Wishes' },
  { sku: 'pongal-greetings', text: 'Pongal Greetings' },
  { sku: 'dussehra-wishes', text: 'Dussehra Wishes' },
  { sku: 'durga-puja-greetings', text: 'Durga Puja Greetings' },
  { sku: 'makar-sankranti-wishes', text: 'Makar Sankranti Wishes' },
  { sku: 'lohri-wishes', text: 'Lohri Wishes' },
  { sku: 'janmashtami-greetings', text: 'Janmashtami Greetings' },
  { sku: 'onam-wishes', text: 'Onam Wishes' },
  { sku: 'christmas-in-india', text: 'Christmas In India' },
  { sku: 'new-year-wishes-india', text: 'New Year Wishes India' },
  { sku: 'baisakhi-greetings', text: 'Baisakhi Greetings' },
  { sku: 'ugadi-wishes', text: 'Ugadi Wishes' },
  { sku: 'karva-chauth-wishes', text: 'Karva Chauth Wishes' },
  { sku: 'chhath-puja-greetings', text: 'Chhath Puja Greetings' },
  { sku: 'gudi-padwa-wishes', text: 'Gudi Padwa Wishes' },
  { sku: 'vaisakhi-greetings', text: 'Vaisakhi Greetings' },
  { sku: 'makar-sankranti-celebrations', text: 'Makar Sankranti Celebrations' },
  { sku: 'indian-festival-greetings', text: 'Indian Festival Greetings' },
  { sku: 'happy-diwali', text: 'Happy Diwali' },
  { sku: 'happy-holi', text: 'Happy Holi' },
  { sku: 'eid-greetings', text: 'Eid Greetings' },
  { sku: 'ganesh-festival-wishes', text: 'Ganesh Festival Wishes' },
  { sku: 'rakhi-wishes', text: 'Rakhi Wishes' },
  { sku: 'pongal-celebrations', text: 'Pongal Celebrations' },
  { sku: 'durga-puja-wishes', text: 'Durga Puja Wishes' },
  { sku: 'navratri-festival-greetings', text: 'Navratri Festival Greetings' },
  { sku: 'diwali-festival-wishes', text: 'Diwali Festival Wishes' },
  { sku: 'holi-festival-greetings', text: 'Holi Festival Greetings' },
  { sku: 'navratri-festival-wishes', text: 'Navratri Festival Wishes' },
  { sku: 'eid-al-fitr-greetings', text: 'Eid Al-Fitr Greetings' },
  { sku: 'ganesh-chaturthi-celebrations', text: 'Ganesh Chaturthi Celebrations' },
  { sku: 'raksha-bandhan-messages', text: 'Raksha Bandhan Messages' },
  { sku: 'pongal-festival-wishes', text: 'Pongal Festival Wishes' },
  { sku: 'dussehra-festival-greetings', text: 'Dussehra Festival Greetings' },
  { sku: 'durga-puja-celebrations', text: 'Durga Puja Celebrations' },
  { sku: 'makar-sankranti-festival-wishes', text: 'Makar Sankranti Festival Wishes' },
  { sku: 'lohri-festival-greetings', text: 'Lohri Festival Greetings' },
  { sku: 'janmashtami-festival-wishes', text: 'Janmashtami Festival Wishes' },
  { sku: 'onam-festival-greetings', text: 'Onam Festival Greetings' },
  { sku: 'christmas-celebrations-in-india', text: 'Christmas Celebrations In India' },
  { sku: 'new-year-celebrations-india', text: 'New Year Celebrations India' },
  { sku: 'baisakhi-festival-wishes', text: 'Baisakhi Festival Wishes' },
  { sku: 'ugadi-festival-greetings', text: 'Ugadi Festival Greetings' },
  { sku: 'karva-chauth-festival-wishes', text: 'Karva Chauth Festival Wishes' },
  { sku: 'chhath-puja-festival-greetings', text: 'Chhath Puja Festival Greetings' },
  { sku: 'gudi-padwa-festival-wishes', text: 'Gudi Padwa Festival Wishes' },
  { sku: 'vaisakhi-festival-wishes', text: 'Vaisakhi Festival Wishes' },
  { sku: 'makar-sankranti-festival-greetings', text: 'Makar Sankranti Festival Greetings' },
  { sku: 'indian-festival-wishes-and-greetings', text: 'Indian Festival Wishes And Greetings' },
  { sku: 'happy-diwali-messages', text: 'Happy Diwali Messages' },
  { sku: 'happy-holi-wishes', text: 'Happy Holi Wishes' },
  { sku: 'eid-greetings-and-wishes', text: 'Eid Greetings And Wishes' },
  { sku: 'ganesh-chaturthi-festival-wishes', text: 'Ganesh Chaturthi Festival Wishes' },
  { sku: 'rakhi-festival-greetings', text: 'Rakhi Festival Greetings' },
  { sku: 'pongal-festival-messages', text: 'Pongal Festival Messages' },
  { sku: 'durga-puja-festival-wishes', text: 'Durga Puja Festival Wishes' },
  { sku: 'navratri-festival-greetings-and-wishes', text: 'Navratri Festival Greetings And Wishes' }
];

export const festivalSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  message: z.string()
});

export const festivalWishesForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' }
];

export const festivalRakhiWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'You are the most special person in my life and I pray that all your dreams come true. May you always be happy and healthy! Happy Raksha Bandhan.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'आप मेरे जीवन के सबसे खास व्यक्ति हैं और मैं प्रार्थना करता हूं कि आपके सभी सपने सच हों। आप सदैव खुश एवं स्वस्थ रहें! हैप्पी रक्षाबंधन.'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'అనుబంధాల హరివిల్లు ప్రేమాభిమానాల పొదరిల్లు గిల్లికజ్జాల సరదాలు తోడు నీడగా సాగిన జీవితాలు కాలం మారినా.. దూరం పెరిగినా.. చెరగని బంధాలు.. అవే అన్నా చెల్లెళ్ల అనుబంధాలు.. కలకాలం నిలవాలి ఈ రక్షాబంధాలు. రక్షా బంధన్ శుభాకాంక్షలు'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'ನೀವು ನನ್ನ ಜೀವನದಲ್ಲಿ ಅತ್ಯಂತ ವಿಶೇಷ ವ್ಯಕ್ತಿ ಮತ್ತು ನಿಮ್ಮ ಎಲ್ಲಾ ಕನಸುಗಳು ನನಸಾಗಲಿ ಎಂದು ನಾನು ಪ್ರಾರ್ಥಿಸುತ್ತೇನೆ. ನೀವು ಯಾವಾಗಲೂ ಸಂತೋಷ ಮತ್ತು ಆರೋಗ್ಯಕರವಾಗಿರಲಿ! ರಕ್ಷಾ ಬಂಧನದ ಶುಭಾಶಯಗಳು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'நீங்கள் என் வாழ்க்கையில் மிகவும் சிறப்பு மிக்க நபர், உங்கள் அனைத்து கனவுகளும் நனவாகிட வேண்டும் என்று நான் பிரார்த்திக்கிறேன். நீங்கள் எப்போதும் மகிழ்ச்சியாகவும் ஆரோக்கியமாகவும் இருக்க வேண்டும்! ரக்ஷா பந்தன் நல்வாழ்த்துக்கள்.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Raksha Bhandan Wishes',
      description: 'Create Raksha bandhan wishes and share with family and friends.',
      message:
        'നിങ്ങൾ എന്റെ ജീവിതത്തിലെ ഏറ്റവും പ്രത്യേകമായ വ്യക്തിയാണ്, നിങ്ങളുടെ എല്ലാ സ്വപ്നങ്ങളും സഫലമാകണമെന്ന് ഞാൻ പ്രാർത്ഥിക്കുന്നു. നിങ്ങൾ എപ്പോഴും സന്തോഷവും ആരോഗ്യവും അനുഭവിക്കട്ടെ! ഹാപ്പി രക്ഷാബന്ധൻ.'
    }
  }
};

export const festivalRamadanWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message: 'Sending you heartfelt wishes for a Ramadan filled with love, harmony, and countless blessings. Happy Ramadhan.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message: 'आपको प्रेम, सद्भाव और अनगिनत आशीर्वाद से भरे रमज़ान की हार्दिक शुभकामनाएँ। रमज़ान मुबारक.'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message:
        'ఈ రంజాన్, మీ ప్రార్థనలకు సమాధానాలు లభిస్తాయని మరియు అవి మిమ్మల్ని అల్లాహ్‌కు మరియు అంతర్గత శాంతికి దగ్గరగా తీసుకువస్తాయని నేను ఆశిస్తున్నాను. రంజాన్ శుభాకాంక్షలు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message:
        'ಪ್ರೀತಿ, ಸಾಮರಸ್ಯ ಮತ್ತು ಅಸಂಖ್ಯಾತ ಆಶೀರ್ವಾದಗಳಿಂದ ತುಂಬಿದ ರಂಜಾನ್‌ಗಾಗಿ ನಿಮಗೆ ಹೃತ್ಪೂರ್ವಕ ಶುಭಾಶಯಗಳನ್ನು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ. ರಂಜಾನ್ ಹಬ್ಬದ ಶುಭಾಶಯಗಳು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message: 'காதல், ஒற்றுமை மற்றும் எண்ணற்ற ஆசீர்வாதங்களால் நிரம்பிய ரமழானுக்கான நெஞ்சார்ந்த வாழ்த்துகளை உங்களிடம் அனுப்புகிறேன். ஹேப்பி ரமழான்.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Ramadan Wishes',
      description: 'Create Ramadhan wishes and share with family and friends.',
      message: 'സ്നേഹത്തോടെ, ഐക്യത്തോടും അനവധി അനുഗ്രഹങ്ങളോടും കൂടിയ ഒരു റമദാൻ ആശംസകളയക്കുന്നു. ഹാപ്പി റമദാൻ.'
    }
  }
};

export const festivalDiwaliWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message:
        'May the joy, cheer, mirth and merriment of this divine festival surround you forever. May the happiness that this season brings. Happy Diwali.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message: 'इस दिव्य त्योहार की खुशी, उत्साह, उल्लास और उल्लास आपको हमेशा घेरे रखें। यह मौसम खुशियाँ लेकर आए। दीपावली की हार्दिक शुभकामनाएं।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message: 'ఈ దీపావళి నాడు, మీరు మంచి ఆరోగ్యం, సంపద మరియు విజయంతో ఆశీర్వదించబడండి. మీకు మరియు మీ కుటుంబ సభ్యులకు దీపావళి శుభాకాంక్షలు!'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message: 'ಈ ದೈವಿಕ ಹಬ್ಬದ ಸಂತೋಷ, ಉಲ್ಲಾಸ, ಉಲ್ಲಾಸ ಮತ್ತು ಉಲ್ಲಾಸವು ನಿಮ್ಮನ್ನು ಶಾಶ್ವತವಾಗಿ ಸುತ್ತುವರಿಯಲಿ. ಈ ಋತುವಿನ ಸಂತೋಷವನ್ನು ತರಲಿ. ದೀಪಾವಳಿಯ ಶುಭಾಶಯಗಳು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message:
        'இந்த தெய்வீகத் திருவிழாவின் மகிழ்ச்சி, சந்தோஷம் மற்றும் ஆனந்தம் என்றும் உங்களை சூழ்ந்து கொள்ளட்டும். இந்த பருவம் தரும் மகிழ்ச்சி என்றும் நீடிக்கட்டும். ஹேப்பி தீபாவளி.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Diwali Wishes',
      description: 'Create Diwali wishes and share with family and friends.',
      message:
        'ഈ ദൈവിക ഉത്സവത്തിന്റെ സന്തോഷം, ആനന്ദം, ഉത്സാഹം എന്നും നിങ്ങളെ ചുറ്റിയിരിക്കട്ടെ. ഈ സീസൺ കൊണ്ടുവരുന്ന സന്തോഷം എന്നും നീളട്ടെ. ഹാപ്പി ദീപാവലി.'
    }
  }
};

export const festivalHoliWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message: 'May the colours of Holi paint your life with happiness, prosperity, and success. Happy Holi to you and your family!'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message: 'होली के रंग आपके जीवन को सुख, समृद्धि और सफलता से रंग दें। आपको और आपके परिवार को होली की शुभकामनाएँ!'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message: 'ఈ హోలీ మీ జీవితంలో ఆనందం, ప్రేమ మరియు స్నేహం యొక్క రంగులను తీసుకురావాలి. మీకు మరియు మీ కుటుంబ సభ్యులకు హోలీ శుభాకాంక్షలు!'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message: 'ಹೋಳಿಯ ಬಣ್ಣಗಳು ನಿಮ್ಮ ಜೀವನವನ್ನು ಸಂತೋಷ, ಸಮೃದ್ಧಿ ಮತ್ತು ಯಶಸ್ಸಿನಿಂದ ಚಿತ್ರಿಸಲಿ. ನಿಮಗೆ ಮತ್ತು ನಿಮ್ಮ ಕುಟುಂಬಕ್ಕೆ ಹೋಳಿ ಹಬ್ಬದ ಶುಭಾಶಯಗಳು!'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message:
        'ஹோலியின் நிறங்கள் உங்கள் வாழ்க்கையை சந்தோஷம், செழிப்பு மற்றும் வெற்றியுடன் நிறைக்கட்டும். உங்களுக்கும் உங்கள் குடும்பத்திற்கும் ஹேப்பி ஹோலி!'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Holi Wishes',
      description: 'Create Holi wishes and share with family and friends.',
      message: 'ഹോളിയുടെ നിറങ്ങൾ നിങ്ങളുടെ ജീവിതം സന്തോഷം, സമൃദ്ധി, വിജയം എന്നിവ കൊണ്ട് നിറയട്ടെ. നിങ്ങൾക്കും നിങ്ങളുടെ കുടുംബത്തിനും ഹാപ്പി ഹോളി!'
    }
  }
};

export const festivalChristmasWishesLanguages = {
  english: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message:
        'May all that is beautiful, meaningful and brings you joy be yours this holiday season and throughout the coming year! Happy Christmas.'
    }
  },
  hindi: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message:
        'इस छुट्टियों के मौसम में और आने वाले पूरे वर्ष में जो कुछ भी सुंदर, अर्थपूर्ण और आपको खुशी देता है वह सब आपके लिए हो! क्रिसमस की शुभकामना।'
    }
  },
  telugu: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message:
        'ఈ క్రిస్మస్.. మీ జీవితంలో సంతోషాన్ని నింపాలని, మీ ఇంట ఆనందపు కాంతులు వెదజల్లాలని కోరుకుంటూ. మీకు, మీ కుటుంబ సభ్యులకు క్రిస్మస్ శుభాకాంక్షలు.'
    }
  },
  kannada: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message: 'ಈ ರಜಾದಿನಗಳಲ್ಲಿ ಮತ್ತು ಮುಂಬರುವ ವರ್ಷವಿಡೀ ಸುಂದರವಾದ, ಅರ್ಥಪೂರ್ಣ ಮತ್ತು ನಿಮಗೆ ಸಂತೋಷವನ್ನು ತರುವಂತಹ ಎಲ್ಲವೂ ನಿಮ್ಮದಾಗಲಿ! ಕ್ರಿಸ್ಮಸ್ ಶುಭಾಶಯಗಳು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message:
        'இந்த பண்டிகை காலத்திலும் வரவிருக்கும் ஆண்டிலும் அழகானதும் அர்த்தமுள்ளதுமாகவும் உங்களுக்கு மகிழ்ச்சியைத் தருவதாகவும் இருப்பவைகள் அனைத்தும் உங்களுடையதாக இருக்கட்டும்! உங்கள் குடும்பத்திற்கு ஹேப்பி கிறிஸ்துமஸ்!'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Christmas Wishes',
      description: 'Create Christmas wishes and share with family and friends.',
      message:
        'ഈ ആഘോഷകാലത്ത് മുതൽ വരുന്ന വർഷം മുഴുവനും എല്ലാ സൗന്ദര്യവും, അർത്ഥവത്തായതും, സന്തോഷം നിറഞ്ഞതും നിങ്ങളുടെ ജീവിതത്തിലായിരിക്കട്ടെ! ഹാപ്പി ക്രിസ്മസ്!'
    }
  }
};

export const festivalSnowmanWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Snowman Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'Wishing you a Happy Snowman Day! May your day be as delightful and full of cheer as a snowman’s smile on a crisp winter morning. Happy Snowman Day.'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Snowman Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'आपको स्नोमैन दिवस की शुभकामनाएँ! आपका दिन सर्दी की सुबह में हिममानव की मुस्कान की तरह आनंददायक और प्रसन्नता से भरा हो। हैप्पी स्नोमैन डे.'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Snowman day Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'మీకు హ్యాపీ స్నోమన్ డే! మీ రోజు స్నోమన్ యొక్క నవ్వుతో కూడిన శీతల ఉదయంలా ఆనందకరమైనది మరియు హర్షభరితమైనది కావాలని ఆశిస్తున్నాను. స్నోమన్ డే శుభాకాంక్షలు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Snowman Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'ನಿಮಗೆ ಹಿಮಮಾನವ ದಿನದ ಶುಭಾಶಯಗಳು! ಗರಿಗರಿಯಾದ ಚಳಿಗಾಲದ ಬೆಳಿಗ್ಗೆ ಹಿಮಮಾನವನ ನಗುವಿನಂತೆ ನಿಮ್ಮ ದಿನವು ಸಂತೋಷಕರ ಮತ್ತು ಉಲ್ಲಾಸದಿಂದ ತುಂಬಿರಲಿ. ಹಿಮಮಾನವ ದಿನದ ಶುಭಾಶಯಗಳು.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Snowman Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'உங்களுக்கு மகிழ்ச்சியான ச்னோமேன் தினம் வாழ்த்துகிறேன்! குளிர்ந்த குளிர்கால காலையில் ச்னோமேன் புன்னகை போலவே உங்கள் நாள் மகிழ்ச்சியாலும் சந்தோஷத்தாலும் நிறைய இருக்கட்டும். ஹேப்பி ச்னோமேன் டே!'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Snowman Wishes',
      description: 'Create Snowman wishes and share with family and friends.',
      message:
        'സന്തോഷകരമായ സ്നോമാൻ ദിനാശംസകൾ! തണുത്ത ശീതകാല പകലിൽ സ്നോമാന്റെ പുഞ്ചിരി പോലെ നിങ്ങളുടെ ദിവസം സന്തോഷവും ആവേശവും നിറഞ്ഞതായിരിക്കട്ടെ. ഹാപ്പി സ്നോമാൻ ഡേ!'
    }
  }
};

export const festivalIndependenceWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: "Let's celebrate the power of unity that brought us independence and continues to guide our nation forward."
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: 'आइए एकता की उस शक्ति का जश्न मनाएं जिसने हमें आजादी दिलाई और हमारे देश को आगे ले जाने में मार्गदर्शन करती रही।'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: 'మన స్వేచ్ఛా స్వాతంత్ర్యాల కోసం అశువులు బాసిన సమర యోధుల దీక్షా దక్షతలను స్మరిస్తూ... స్వాతంత్ర్య దినోత్సవ శుభాకాంక్షలు.'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: 'ನಮಗೆ ಸ್ವಾತಂತ್ರ್ಯ ತಂದುಕೊಟ್ಟ ಮತ್ತು ನಮ್ಮ ರಾಷ್ಟ್ರವನ್ನು ಮುನ್ನಡೆಸುವ ಏಕತೆಯ ಶಕ್ತಿಯನ್ನು ಆಚರಿಸೋಣ.'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: 'நம்மை சுதந்திரம் அடையச் செய்ததும், நமது நாட்டை முன்னேற்றுவதற்கு வழிகாட்டுவதாக உள்ள ஒற்றுமையின் சக்தியை கொண்டாடுவோம்.'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Independence Day Wishes',
      description: 'Create Independence day wishes and share with family and friends.',
      message: 'നമ്മെ സ്വാതന്ത്ര്യത്തിലേക്ക് നയിച്ച ഐക്യത്തിന്റെ ശക്തിയെ കൂടി, നമ്മുടെ രാജ്യം മുന്നോട്ടുപോകാൻ വഴികാട്ടുന്ന ആ ശക്തിയെ ആഘോഷിക്കാം.'
    }
  }
};
