import { Link } from 'react-router-dom';
import { PRICING } from '@/shared/config/constants.ts';

const pricings = [
  {
    title: 'Free',
    description: 'Lifetime free for users with watermark and low resolution videos.',
    button: 'Start Creating',
    price: PRICING.FREE,
    cycle: 'video',
    features: [
      'Web and Mobile applications.',
      'Social Media Integration for sharing.',
      'Supports English, Hindi, Telugu, Kannada, Tamil and Malayalam.',
      'Max 20 videos creation.',
      'Watermark added on all videos.',
      'Low resolution on all videos.',
      'Scheduling is not available on free videos.',
      'Generated videos will not sent to your whatsapp for free videos.',
      'Pro Templates are not included.'
    ]
  },
  {
    title: 'Pro Video',
    description: 'Pay per video and Get watermark free and high resolution videos.',
    button: 'Start Creating',
    price: PRICING.VIDEO,
    cycle: 'video',
    features: [
      'Web and Mobile applications.',
      'Social Media Integration for sharing.',
      'Supports English, Hindi, Telugu, Kannada, Tamil and Malayalam.',
      'Max 20 videos creation.',
      'Watermark removed on paid videos.',
      'High resolution on paid videos.',
      'Scheduling is only available on paid videos.',
      'Generated videos will sent to your whatsapp for paid videos.',
      'Pro Templates are not included.',
      '24/7 Email support on paid videos.',
      'Analytics and Insights on paid videos.'
    ]
  },
  {
    title: 'Pro Monthly',
    description: 'Pay per month and Get watermark free and high resolution videos.',
    button: 'Start Creating',
    price: PRICING.MONTHLY,
    cycle: 'month',
    features: [
      'Web and Mobile applications.',
      'Social Media Integration for sharing.',
      'Supports English, Hindi, Telugu, Kannada, Tamil and Malayalam.',
      'Max 20 HD videos creation per month.',
      'Watermark removed on all videos.',
      'High resolution on all videos.',
      'Scheduling is available on all videos.',
      'Generated videos will sent to your whatsapp for all videos.',
      'Pro Templates are included.',
      '24/7 Email and Whatsapp support.',
      'Analytics and Insights for tracking.',
      'Beta access for new templates and features.'
    ]
  },
  {
    title: 'Pro Yearly',
    description: 'Pay per year and Get watermark free and high resolution videos.',
    button: 'Start Creating',
    price: PRICING.YEARLY,
    cycle: 'year',
    features: [
      'Web and Mobile applications.',
      'Social Media Integration for sharing.',
      'Supports English, Hindi, Telugu, Kannada, Tamil and Malayalam.',
      'Max 200 HD videos creation per year.',
      'Watermark removed on all videos.',
      'High resolution on all videos.',
      'Scheduling is available on all videos.',
      'Generated videos will sent to your whatsapp for all videos.',
      'Pro Templates are included.',
      '24/7 Email and Whatsapp support.',
      'Analytics and Insights for tracking.',
      'Beta access for new templates and features.'
    ]
  }
];

const Pricing = () => {
  return (
    <section className="bg-background flex flex-col justify-center text mb-16" id="pricing">
      <div className="container mx-auto px-3 sm:px-0">
        <div className="h-full">
          <div className="mx-auto">
            <div className="text-xl text text-center mt-12 mb-5">PRICING</div>
            <div className="text text-base mb-8 text-center max-w-[900px] mx-auto">
              Get the power, control, and customization you need to manage your invitations and wishes. if no plan matches for your requirement
              contact us at{' '}
              <a href="mailto:hello@inyter.com" target="_blank" className="text-primary no-underline">
                hello@inyter.com
              </a>{' '}
              we will be more flexible to meet your requirements.
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 gap-3">
              {pricings.map(pricing => (
                <div key={pricing.title} className="relative col-span-1 bg-card shadow rounded-sm border border-border pricing-item">
                  <div className={`absolute top-0 left-0 right-0 h-0.5 bg-primary`} aria-hidden="true" />
                  <div className="p-3 border-0 border-b border-border">
                    <header className="flex items-center mb-2">
                      <div className={`w-6 h-6 rounded-full flex-shrink-0 bg-primary mr-3`}>
                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                          <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text font-semibold">{pricing.title}</h3>
                    </header>
                    <div className="text-sm mb-3 leading-6">{pricing.description}</div>
                    <div className="text font-bold mb-4 h-12">
                      <span className="text-2xl">$</span>
                      <span className="text-3xl">{pricing.price}</span>
                      <span className="text font-medium text-base"> / {pricing.cycle}</span>
                    </div>
                    <div className="h-8">
                      <Link to="/templates" className="bg-primary text-white rounded border-primary px-3 py-3 hover:bg-primary-dark uppercase">
                        {pricing.button}
                      </Link>
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="text-xs text font-semibold uppercase mb-4">What's included</div>
                    <ul>
                      {pricing.features.map(feature => (
                        <li key={feature} className="flex py-2">
                          <svg className="w-3 h-3 flex-shrink-0 fill-current text-primary mr-3 mt-1" viewBox="0 0 12 12">
                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                          </svg>
                          <div className="text-sm">{feature}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
