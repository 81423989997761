import { produce } from 'immer';
import { create } from 'zustand';

export const immerMiddleware = (config: any) => (set: any, get: any, api: any) => config((fn: any) => set(produce(fn)), get, api);

const userStore = () => ({
  UUID: null,
  UNAME: null,
  UEMAIL: null,
  UMOBILE: null,
  UDOB: null,
  UABOUT: null,
  TOKEN: null,
  PUSH: null
});

export const useUserStore = create(immerMiddleware(userStore));
