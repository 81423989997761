import { PRICING } from '@/shared/config/constants.ts';
import { priceConvertion } from '@/shared/services/PaymentsService.ts';
import Payment from './Payment';

interface Props {
  videoUuid: string;
}

const PaymentBanner = (props: Props) => {
  return (
    <div className="flex justify-between items-center border border-border rounded-lg p-2 mb-3 bg-background">
      <div className="pr-2 leading-relaxed">
        Pay <strong>${PRICING.VIDEO}</strong> to get Watermark free HD videos & schedule wishes to your friends & family.
      </div>
      <Payment title={`Pay $${PRICING.VIDEO}`} amount={priceConvertion(PRICING.VIDEO)} videoUuid={props.videoUuid} />
    </div>
  );
};

export default PaymentBanner;
