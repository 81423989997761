import { staticFile } from 'remotion';
import { textBorderShadowStyle, WATERMARK_POSITION } from '@/ui/shared/config/constants';
import { Image } from './Image';
import { Text } from './Text';

const classesMapping = {
  [WATERMARK_POSITION.TOPLEFT]: 'top-0 left-0',
  [WATERMARK_POSITION.TOPRIGHT]: 'top-0 right-0',
  [WATERMARK_POSITION.TOPCENTER]: 'top-0 left-0 right-0 flex justify-center',
  [WATERMARK_POSITION.BOTTOMLEFT]: 'bottom-0 left-0',
  [WATERMARK_POSITION.BOTTOMRIGHT]: 'bottom-0 right-0',
  [WATERMARK_POSITION.BOTTOMCENTER]: 'bottom-0 left-0 right-0 flex justify-center',
  [WATERMARK_POSITION.MIDDLELEFT]: 'left-0 top-1/2 transform -translate-y-1/2 rotate-90 -translate-x-[90px]',
  [WATERMARK_POSITION.MIDDLERIGHT]: 'right-0 top-1/2 transform -translate-y-1/2 -rotate-90 translate-x-[90px]'
};

interface Props {
  template: any;
  color?: string;
}

export const Watermark = (props: Props) => {
  const { template } = props;

  return (
    <div className={`absolute px-5 py-3 ${classesMapping[template?.watermarkPosition] || classesMapping[WATERMARK_POSITION.TOPRIGHT]}`}>
      <div className="flex flex-row items-center" style={{ fontFamily: 'Inter, san-serf' }}>
        <Image from={0} to={5} source={staticFile('/logo-small.webp')} className="mr-2 w-[60px] h-[60px]" />
        <div>
          <Text from={0} to={5} className="text-[20px] -mt-[5px] font-semibold text-black" color="#000000" style={textBorderShadowStyle}>
            www.inyter.com
          </Text>
          <Text from={0} to={5} className="text-[16px] -mt-[0px] font-semibold text-black" color="#000000" style={textBorderShadowStyle}>
            Invitations & Wishes
          </Text>
        </div>
      </div>
    </div>
  );
};
